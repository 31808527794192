import { SVGProps, memo } from "react";

function Generic(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_536_17744)">
                <circle cx={8} cy={8} r={8} fill="#2E72FF" />
            </g>
        </svg>
    );
}

const MemoGeneric = memo(Generic);
export default MemoGeneric;
