import Modal from "@components/Modal";
import { Button } from "@/components";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { cloneFilterSet } from "@/services/news";
import Cookies from "js-cookie";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { Dispatch, SetStateAction, useState } from "react";
import Input from "@/components/form-elements/Input";
import { FilterSet } from "@/types/util";
import { includes } from "lodash";

export const ConfirmCloneWatchlistModal = ({
    showConfirmCloneWatchlistModal,
    setShowConfirmCloneWatchlistModal,
}: {
    showConfirmCloneWatchlistModal: boolean;
    setShowConfirmCloneWatchlistModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const { filterSet, setFilterSet, setFilterSets, allFilterSets } = useNewsFeedContext();

    const [filterSetName, setFilterSetName] = useState("");

    const existingNames = allFilterSets.map((f) => f.filterName?.toLowerCase());

    const handleCloneWatchlist = (e) => {
        e.preventDefault();
        if (!filterSetName || includes(existingNames, filterSetName.toLowerCase())) {
            return;
        }

        try {
            cloneFilterSet({
                filterSet: {
                    ...filterSet,
                    filterName: filterSetName,
                },
                jwt: Cookies.get("stytch_session_jwt") as string,
            }).then((res) => {
                setFilterSets(res as FilterSet[]);
                setFilterSet(res[res.length - 1]); //this causes the news feed to rerender
                setFilterSetName("");
            });
            setShowConfirmCloneWatchlistModal(false);
        } catch (e) {
            console.error(e);
            setShowConfirmCloneWatchlistModal(false);
        }
    };

    return (
        <div className="relative z-[1000]">
            <Modal
                show={showConfirmCloneWatchlistModal}
                setShow={setShowConfirmCloneWatchlistModal}
                className="overflow-hidden px-0 pb-0 pt-0"
            >
                <form
                    onSubmit={handleCloneWatchlist}
                    className="flex w-[400px] flex-col items-center justify-center overflow-hidden rounded-lg bg-[#1C1F27] p-0"
                >
                    <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                        <div className="flex h-9 w-9 items-center justify-center rounded-full border-1 border-[#2F343F]">
                            <FunnelIcon className="h-[14px] w-[14px] text-[#7A869B]" strokeWidth={2.5} />
                        </div>
                        <div className="flex w-full flex-col items-center gap-4 px-0 pb-0 pt-2">
                            <h2 className="text-base text-white">Set FilterSet Name</h2>

                            <Input
                                className="h-10 gap-2 rounded-lg border-1 border-[#2F343F] bg-[#15181D] p-3 text-base text-[#A1ADC1]"
                                input={filterSetName}
                                setInput={setFilterSetName}
                                placeholder="FilterSet Name"
                            />
                        </div>
                    </div>
                    <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                        <Button
                            onClick={() => setShowConfirmCloneWatchlistModal(false)}
                            className="flex h-10 w-[180px] items-center justify-center gap-[10px] rounded-lg border-none bg-[#252932] px-[10px] py-3"
                        >
                            <p className="text-xs font-normal text-white">Close this</p>
                        </Button>
                        <Button
                            type="submit"
                            onClick={handleCloneWatchlist}
                            // this is so we don't set the filterset as the "Customized" one, because it is not meant to be seen by the user so they don't feel one has been automatically created for them
                            disabled={!filterSetName || includes(existingNames, filterSetName.toLowerCase())}
                            className="flex h-10 w-[180px] items-center justify-center gap-[10px] rounded-lg border-none bg-[#2E72FF] px-[10px] py-3"
                        >
                            <p
                                className={`text-xs font-normal ${
                                    !filterSetName ? "text-[#A1ADC1]" : "text-white"
                                }`}
                            >
                                Create
                            </p>
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};
