"use client";

import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

interface FilterContextType {
    setPage: (page: number) => void;
    coinOptions: any[];
    startDate: Date | null;
    setStartDate: Dispatch<SetStateAction<Date | null>>;
    endDate: Date | null;
    setEndDate: Dispatch<SetStateAction<Date | null>>;
    startTime: string;
    setStartTime: (time: string) => void;
    endTime: string;
    setEndTime: (time: string) => void;
    showConfirmCloneWatchlistModal: boolean;
    setShowConfirmCloneWatchlistModal: Dispatch<SetStateAction<boolean>>;
    showUpgradeModal: boolean;
    setShowUpgradeModal: Dispatch<SetStateAction<boolean>>;
    saveButton: boolean;
    showSaveButton: Dispatch<SetStateAction<boolean>>;
    upgradeMessage: string;
    setUpgradeMessage: Dispatch<SetStateAction<string>>;
}

const FilterContext = createContext<FilterContextType>({} as FilterContextType);

const FilterContextProvider: React.FC = ({ children }) => {
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("23:59");
    const [showConfirmCloneWatchlistModal, setShowConfirmCloneWatchlistModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [saveButton, showSaveButton] = useState(false);
    const [upgradeMessage, setUpgradeMessage] = useState("");
    const [coinOptions] = useState([
        { name: "All Coins" },
        { name: "Top 50" },
        { name: "Top 100" },
        { name: "Top 200" },
        { name: "Top 500" },
        { name: "Top 1000" },
    ]);

    return (
        <FilterContext.Provider
            value={{
                setPage,
                coinOptions,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                startTime,
                setStartTime,
                endTime,
                setEndTime,
                showConfirmCloneWatchlistModal,
                setShowConfirmCloneWatchlistModal,
                showUpgradeModal,
                setShowUpgradeModal,
                saveButton,
                showSaveButton,
                upgradeMessage,
                setUpgradeMessage,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
const useFilterContext = () => useContext(FilterContext);

export { useFilterContext, FilterContextProvider };
