import { ISymbolName } from "@app/TSWidgets/tswidgets";

const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
// const host = env === "production" ? "prod" : "dev";
const API_URL =
    process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
    (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`);

export async function fetchMyWatchlists(jwt: string): Promise<any> {
    const res = await fetch(`${API_URL}/watchlists/my-watchlists`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const createNewWatchlist = async ({
    jwt,
    name,
    symbols,
}: {
    jwt: string;
    name: string;
    symbols: ISymbolName[];
}) => {
    const res = await fetch(`${API_URL}/watchlists/create-watchlist`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, symbols }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const updateWatchlist = async ({
    jwt,
    name,
    symbols,
    date_created,
}: {
    jwt: string;
    name: string;
    symbols: ISymbolName[];
    date_created: number;
}) => {
    const res = await fetch(`${API_URL}/watchlists/update-watchlist`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, symbols, date_created }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const deleteWatchlist = async ({
    jwt,
    name,
    date_created,
}: {
    jwt: string;
    name: string;
    date_created: number;
}) => {
    const res = await fetch(`${API_URL}/watchlists/delete-watchlist`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, date_created }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};
