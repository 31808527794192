import { Button } from "@components";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import SubNav from "@components/layouts/SubNav";
import Input from "@form-elements/Input";
import Checkbox from "@form-elements/Checkbox";
import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import { Dispatch, SetStateAction } from "react";

export default function Customization({
    tab,
    setTab,
    twitterUsername,
    setTwitterUsername,
    redditUsername,
    setRedditUsername,
    setPage,
    customization_tabs,
    twitterSources,
    redditSources,
}: {
    tab: string;
    setTab: Dispatch<SetStateAction<string>>;
    twitterUsername: string;
    setTwitterUsername: Dispatch<SetStateAction<string>>;
    redditUsername: string;
    setRedditUsername: Dispatch<SetStateAction<string>>;
    setPage: Dispatch<SetStateAction<CUSTOMIZE>>;
    customization_tabs: string[];
    twitterSources: {
        name: string;
        accounts: {
            name: string;
            status: boolean;
        }[];
    }[];
    redditSources: {
        name: string;
        accounts: {
            name: string;
            status: boolean;
        }[];
    }[];
}) {
    return (
        <div className="flex h-screen w-full flex-col">
            <div className="flex w-full flex-col items-start border-b-1 border-[#2F343F] px-4 py-3">
                <div className="flex items-center gap-2">
                    <ArrowLeftIcon
                        className="h-4 w-4 cursor-pointer text-[#7A869B] hover:brightness-75"
                        onClick={() => setPage(CUSTOMIZE.LANDING_PAGE)}
                    />
                    <h1 className="text-base font-normal leading-5 text-white">Сustomize Sources</h1>
                </div>
            </div>
            <div className="scrollbar-hide flex h-screen flex-col items-start gap-3 overflow-auto bg-[#20242C] px-4 py-0">
                <SubNav
                    selectedTab={tab}
                    tabs={customization_tabs}
                    onClick={setTab}
                    className="scrollbar-hide w-[360px] overflow-auto border-b-1 border-[#2F343F]"
                />
                {tab === "Twitter" && (
                    <div className="flex w-full flex-col items-start gap-3 p-0">
                        <div className="flex w-full flex-col items-start gap-2 p-0">
                            <Input
                                input={twitterUsername}
                                setInput={setTwitterUsername}
                                placeholder="@ Enter X username"
                                className="h-10 w-full gap-2 rounded-lg p-3 text-xs font-light text-[#A1ADC1]"
                            />
                            <Button
                                className="h-10 w-full items-center justify-center gap-[10px] rounded-lg border-none bg-[#306CE8] px-4 py-3"
                                disabled={true}
                            >
                                <p className="h-full w-full text-xs font-normal text-white">Add source</p>
                            </Button>
                        </div>
                        <div className="flex w-full flex-col items-start gap-3 p-0">
                            <div className="flex w-full items-center gap-2 p-0">
                                <Checkbox className="h-4 w-4 rounded" />
                                <p className="text-xs font-normal text-white">See all Reddit news</p>
                            </div>
                            <div className="flex w-full flex-col items-start gap-2 p-0">
                                <p className="text-[10px] font-light leading-3 text-[#A1ADC1]">MY SOURCES</p>
                                {twitterSources[0].accounts.map((source, i) => (
                                    <div key={i} className="flex w-full items-center justify-between p-0">
                                        <div className="flex items-center gap-2">
                                            <Checkbox
                                                className="h-4 w-4 rounded"
                                                checked={source.status}
                                                onChange={() => console.log(true)}
                                            />
                                            <p className="text-xs font-normal text-white">{source.name}</p>
                                        </div>
                                        <div className="flex h-4 w-4 items-center justify-center">
                                            <XMarkIcon className="h-[10px] w-[10px]" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex w-full flex-col items-start gap-2 p-0">
                                <p className="text-[10px] font-light uppercase leading-3 text-[#A1ADC1]">
                                    BlockBeat Sources
                                </p>

                                {twitterSources[1].accounts.map((source, i) => (
                                    <div key={i} className="flex w-full items-center gap-2 p-0">
                                        <Checkbox
                                            className="h-4 w-4 rounded"
                                            checked={source.status}
                                            onChange={() => console.log(true)}
                                        />
                                        <p className="text-xs font-normal text-white">{source.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {tab === "Reddit" && (
                    <div className="flex w-full flex-col items-start gap-3 p-0">
                        <div className="flex w-full flex-col items-start gap-2 p-0">
                            <div className="flex w-full flex-col items-start gap-1 p-0">
                                <Input
                                    input={redditUsername}
                                    setInput={setRedditUsername}
                                    placeholder="r/ Add new Reddit Source"
                                    className="h-10 w-full gap-2 rounded-lg p-3 text-xs font-light text-[#A1ADC1]"
                                />
                                <div className="flex w-full items-center gap-1 px-0 pb-1 pt-0">
                                    <p className="text-[10px] font-light leading-3 text-[#A1ADC1]">
                                        Reddit Posts need 50+ upvotes in order to show in your feed.
                                    </p>
                                </div>
                            </div>

                            <Button // onClick={handleEditWatchlist}
                                className="h-10 w-full items-center justify-center gap-[10px] rounded-lg border-none bg-[#306CE8] px-4 py-3"
                                disabled={true} // disabled={
                            >
                                <p className="h-full w-full text-xs font-normal text-white">Add source</p>
                            </Button>
                        </div>
                        <div className="flex w-full flex-col items-start gap-3 p-0">
                            <div className="flex w-full items-center gap-2 p-0">
                                <Checkbox className="h-4 w-4 rounded" />
                                <p className="text-xs font-normal text-white">See all Reddit news</p>
                            </div>
                            <div className="flex w-full flex-col items-start gap-2 p-0">
                                <p className="text-[10px] font-light leading-3 text-[#A1ADC1]">MY SOURCES</p>
                                {redditSources[0].accounts.map((source, i) => (
                                    <div key={i} className="flex w-full items-center justify-between p-0">
                                        <div className="flex items-center gap-2">
                                            <Checkbox
                                                className="h-4 w-4 rounded"
                                                checked={source.status}
                                                onChange={() => console.log(true)}
                                            />
                                            <p className="text-xs font-normal text-white">{source.name}</p>
                                        </div>
                                        <div className="flex h-4 w-4 items-center justify-center">
                                            <XMarkIcon className="h-[10px] w-[10px]" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex w-full flex-col items-start gap-2 p-0">
                                <p className="text-[10px] font-light uppercase leading-3 text-[#A1ADC1]">
                                    BlockBeat Sources
                                </p>

                                {redditSources[1].accounts.map((source, i) => (
                                    <div key={i} className="flex w-full items-center gap-2 p-0">
                                        <Checkbox
                                            className="h-4 w-4 rounded"
                                            checked={source.status}
                                            onChange={() => console.log(true)}
                                        />
                                        <p className="text-xs font-normal text-white">{source.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="absolute bottom-0 flex w-full flex-col items-center gap-3 border-1 border-[#2C323D] bg-[#20242C] px-4 py-3">
                <Button className="h-10 w-full items-center justify-center gap-[10px] rounded-lg border-none bg-[#306CE8] px-4 py-3">
                    <p className="text-xs font-normal text-white">Show 205 results</p>
                </Button>
            </div>
        </div>
    );
}
