import MemoGeneric from "@assets/SVG/Coins/Generic";
import { allAssets } from "@/data/fixtures/UI";

const AssetIcon = ({ acronym = "", className }: { acronym: string; className?: string }) => {
    const asset = allAssets.find(
        (asset) => asset.acronym.toLowerCase() === acronym.replace("$", "").toLowerCase(),
    );
    const AI = asset?.icon || MemoGeneric;
    return <AI className={`${className} flex items-center justify-center`} />;
};

export default AssetIcon;
