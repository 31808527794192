import { PlusIcon } from "@heroicons/react/24/solid";
import MemoCryptonews from "@assets/SVG/Cryptonews";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoReddit from "@assets/SVG/Reddit";
import MemoSpeaker from "@assets/SVG/Speaker";
import MemoX from "@assets/SVG/X";
import { Button } from "@components";
// import RadioInput from "@form-elements/Radio";
import Toggle from "@form-elements/Toggle";
import {
    CheckBadgeIcon,
    EllipsisVerticalIcon,
    MagnifyingGlassIcon,
    PencilSquareIcon,
    TrashIcon,
    // ExclamationTriangleIcon,
    // ChevronRightIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
// import Tooltip from "@/components/Tooltip";
// import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import { deleteFilterSetById, saveFilterSetById } from "@/services/news";
import { isEmpty, isNumber, uniqBy } from "lodash";
import MemoNews from "@assets/SVG/News";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import Cookies from "js-cookie";
import MemoGavel from "@assets/SVG/Gavel";
import MemoYouTube from "@assets/SVG/YouTube";
import MemoPodcast from "@assets/SVG/Podcast";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useMap } from "react-use";
import { FilterSet, UpgradeSection, defaultFilterSets } from "@/types/util";
import MemoLoading from "@assets/SVG/Loading";
import { searchProxy } from "@/store/searchStore";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";
import filterSetList from "@/store/filterSetStore";

export default function LandingPage({
    setFilterSetEdited,
    setShowEditModal,
}: {
    setFilterSetEdited: Dispatch<SetStateAction<FilterSet>>;
    setShowEditModal: Dispatch<SetStateAction<boolean>>;
}) {
    const { startTime, startDate, endTime, endDate } = useFilterContext();
    const { setTimeRange, filterSetChanged, setFilterSetChanged, setOpenFilterModal } = useNewsFeedContext();

    useEffect(() => {
        let startDateUTC, endDateUTC;
        if (startDate) {
            const start = new Date(startDate);
            const timeValue = startTime;
            const [hours, minutes] = timeValue.split(":").map(Number);
            if (isNumber(hours) && isNumber(minutes)) {
                start.setHours(hours);
                start.setMinutes(minutes);
            }
            startDateUTC = start.getTime();
        }
        if (endDate) {
            const end = new Date(endDate);
            const timeValue = endTime;
            const [hours, minutes] = timeValue.split(":").map(Number);
            if (isNumber(hours) && isNumber(minutes)) {
                end.setHours(hours);
                end.setMinutes(minutes);
            }
            endDateUTC = end.getTime();
        }

        if (startDateUTC && endDateUTC && startDateUTC < endDateUTC) {
            const timeRange = [startDateUTC, endDateUTC];
            setTimeRange(timeRange);
        }
    }, [startDate, endDate, startTime, endTime]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setOpenFilterModal(false);
            }}
            className="flex h-screen w-full flex-col"
        >
            <Header />
            <div className="scrollbar-hide flex h-screen flex-col items-start overflow-auto p-0">
                <FilterSetSelection
                    setFilterSetEdited={setFilterSetEdited}
                    setShowEditModal={setShowEditModal}
                    filterSetChanged={filterSetChanged}
                />
                <DateRangePicker />
                <TimeRangePicker />
                {/* <NonAssetSpecificNewsToggle /> */}
                <NewsSources filterSetChanged={filterSetChanged} setFilterSetChanged={setFilterSetChanged} />
                {/* <Coins /> */}
            </div>
            <div className="flex w-full flex-col items-center gap-3 border-t-1 border-[#2F343F] bg-[#20242C] px-4 py-3">
                <FilterActions />
                <ShowResultsButton />
            </div>
        </form>
    );
}

function Header() {
    return (
        <div className="flex w-full flex-col items-start border-b-1 border-[#2F343F] px-4 py-3">
            <div className="flex items-center justify-between">
                <h1 className="text-base font-normal leading-5 text-white">Filters</h1>
            </div>
        </div>
    );
}

function FilterSetSelection({
    setFilterSetEdited,
    setShowEditModal,
    filterSetChanged,
}: {
    setFilterSetEdited: Dispatch<SetStateAction<FilterSet>>;
    setShowEditModal: Dispatch<SetStateAction<boolean>>;
    filterSetChanged: boolean;
}) {
    const { filterSets, filterSet, setFilterSet, openFilterModal, setFilterSets } = useNewsFeedContext();
    const { showUpgradeModal, setShowUpgradeModal, saveButton, showSaveButton, setUpgradeMessage } =
        useFilterContext();
    const { data: fil } = useSnapshot(filterSetList);

    const [showOptions, { set: setShowOptions }] = useMap({} as Record<string, boolean>);
    const [loading, { set: setLoading }] = useMap({} as Record<string, boolean>);

    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    useEffect(() => {
        if (!openFilterModal) {
            Object.keys(showOptions).forEach((index) => {
                // Set the visibility of delete buttons for other filter sets to false
                setShowOptions(index, false);
            });
        }
    }, [openFilterModal]);

    const handleDeleteFilterset = (f: FilterSet, index: string) => {
        setLoading(index, true);

        deleteFilterSetById({ filterSet: f, jwt: Cookies.get("stytch_session_jwt") as string }).then(
            (res) => {
                setLoading(index, false);
                setFilterSets(res as FilterSet[]);

                if (filterSet.filterIndex === f.filterIndex) {
                    if (!isEmpty(res)) {
                        // if after deleting, other custom filtersets are still available
                        if (res[index]) {
                            // if after deleting item number 3,there is another item number 3
                            setFilterSet(res[index]);
                        } else {
                            // if after deleting item number 3,there is no item number 3 again i.e. item.length = 3
                            setFilterSet(res[0]);
                        }
                    } else {
                        // when all custom created filtersets are deleted
                        setFilterSet(defaultFilterSets[0]);
                    }
                }
            },
        );
    };

    const handleShowEditModal = (f: FilterSet, index: string) => {
        setFilterSetEdited(f);
        setShowEditModal(true);
        setShowOptions(index, false);
    };

    useEffect(() => {
        const filInitial = fil.find((f) => f.filterIndex === filterSet.filterIndex);

        if (filInitial && filInitial.filterName !== "Customized") {
            let anyDifference = false;
            Object.keys(filInitial).forEach((key) => {
                if (key in filterSet && filInitial[key] !== filterSet[key]) {
                    anyDifference = true;
                }
            });

            if (anyDifference && !saveButton) {
                showSaveButton(true);
            } else if (!anyDifference && saveButton) {
                showSaveButton(false);
            }
        }
    }, [filterSetChanged]);

    return (
        <div className="relative flex w-full flex-col items-start gap-2 border-b-1 border-[#2F343F] px-4 pb-0 pt-3">
            {/* {showEditModal && (
                <ConfirmEditFilterSetModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    filterSet={filterSetIn}
                />
            )} */}
            <p className="text-xs font-light text-[#A1ADC1]">Use filter set</p>
            <div className="scrollbar-hide flex w-full items-start gap-1 overflow-x-auto pb-8">
                {filterSets
                    .filter(
                        (f) =>
                            f.filterName !== "Customized" &&
                            f.filterName !== "Saved" &&
                            f.filterName !== "All News",
                    )
                    .map((f, index) => {
                        const filterSetInitialState = fil.find(
                            (f) => f.filterIndex === filterSet.filterIndex,
                        );

                        let originalFilterSet = f;
                        if (!isEmpty(filterSetInitialState))
                            originalFilterSet = {
                                ...filterSetInitialState,
                            };

                        // this is so we don't set the filterset as the "Customized" one, because it is not meant to be seen by the user so they don't feel one has been automatically created for them
                        return (
                            <div key={f.filterIndex} className="relative max-w-[100px]">
                                <div
                                    onClick={() => {
                                        if (userIsPro) {
                                            // setFilterSet(f);
                                            if (saveButton) setFilterSet(originalFilterSet);
                                            else setFilterSet(f);
                                        } else {
                                            if (!showUpgradeModal) {
                                                setUpgradeMessage(UpgradeSection.Filter_Creation);
                                                // setOpenFilterModal(false);
                                                setShowUpgradeModal(true);
                                            }
                                        }
                                        showSaveButton(false);
                                    }}
                                    className={`${
                                        f.filterName === filterSet.filterName && !saveButton
                                            ? "bg-[#306CE8]"
                                            : "bg-[#20242C]"
                                    } flex h-8 items-center gap-1 rounded-[50px] border-1 border-[#2F343F] py-2 pl-3 pr-3`}
                                >
                                    <p className="flex items-center truncate text-xs font-light text-white">
                                        {f.filterName}
                                    </p>
                                    {loading[f.filterIndex] ? (
                                        <MemoLoading className="w-3 shrink-0" />
                                    ) : (
                                        <EllipsisVerticalIcon
                                            onClick={(e) => {
                                                if (userIsPro) {
                                                    e.stopPropagation();
                                                    setShowOptions(
                                                        f.filterIndex,
                                                        !showOptions[f.filterIndex],
                                                    );
                                                    Object.keys(showOptions).forEach((index) => {
                                                        // Skip the currently clicked filter index
                                                        if (index !== f.filterIndex) {
                                                            // Set the visibility of delete buttons for other filter sets to false
                                                            setShowOptions(index, false);
                                                        }
                                                    });
                                                } else {
                                                    if (!showUpgradeModal) {
                                                        setUpgradeMessage(UpgradeSection.Filter_Creation);
                                                        // setOpenFilterModal(false);
                                                        setShowUpgradeModal(true);
                                                    }
                                                }
                                            }}
                                            className="h-3 w-3 shrink-0 text-white hover:text-[#A1ADC1]"
                                        />
                                    )}
                                </div>
                                {showOptions[f.filterIndex] ? (
                                    <div
                                        className={`absolute ${
                                            index === 0 ? "-right-5" : "right-0"
                                        } top-[34px] flex items-center gap-4 rounded-lg border-1 border-[#2F343F] bg-[#20242C] px-3 py-1 text-[#A1ADC1]`}
                                    >
                                        <TrashIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteFilterset(f, f.filterIndex);
                                                // setShowEditWatchlistModal(true);
                                            }}
                                            className="h-[12.5px] w-[12.5px] cursor-pointer hover:brightness-75"
                                        />
                                        <PencilSquareIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleShowEditModal(f, f.filterIndex);
                                                // setShowEditWatchlistModal(true);
                                            }}
                                            className="h-[12.5px] w-[12.5px] cursor-pointer hover:brightness-75"
                                        />
                                    </div>
                                ) : (
                                    // filterSet.filterName !== "Saved" &&
                                    filterSet.filterName !== "Customized" &&
                                    saveButton &&
                                    f.filterIndex === filterSet.filterIndex && (
                                        // this is useful so we can use use source toggle buttons for saved news also
                                        <div
                                            onClick={() => {
                                                saveFilterSetById({
                                                    filterSet,
                                                    jwt: Cookies.get("stytch_session_jwt") as string,
                                                });
                                                showSaveButton(false);
                                            }}
                                            className={`absolute ${
                                                index === 0 ? "-right-5" : "right-0"
                                            } top-[34px] flex cursor-pointer items-center rounded-lg border-1 border-[#2F343F] bg-[#20242C] px-3 py-1 text-xs text-[#A1ADC1] hover:brightness-75`}
                                        >
                                            Update
                                        </div>
                                    )
                                )}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

const DateRangePicker = () => {
    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        showUpgradeModal,
        setShowUpgradeModal,
        setUpgradeMessage,
    } = useFilterContext();
    const { setTimeRange } = useNewsFeedContext();
    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    useEffect(() => {
        // This is incase the user selects start an end date earlier than a start date
        if (startDate && endDate) {
            if (startDate > endDate) {
                const previousDate = new Date(endDate);
                previousDate.setDate(previousDate.getDate() - 1);

                setStartDate(previousDate);
            }
        }
    }, [startDate, endDate]);

    return (
        <div className="flex w-full flex-col items-start gap-2 border-b-1 border-[#2F343F] px-4 py-3">
            <div className="flex w-full items-start justify-between gap-2 p-0">
                <p className="text-xs font-light text-[#A1ADC1]">By date</p>
                <p
                    onClick={() => {
                        if (userIsPro) {
                            setStartDate(null);
                            setEndDate(null);
                            setTimeRange([]);
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Customization);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                    className="cursor-pointer text-xs font-normal text-[#306CE8] hover:brightness-75"
                >
                    Clear
                </p>
            </div>
            <div className="filter-date-picker flex w-full items-center justify-between gap-2 p-0">
                <div className="relative">
                    <div className="flex h-10 w-[150px] items-center">
                        <DatePicker
                            selected={startDate}
                            onChange={(date: Date) => {
                                if (userIsPro) {
                                    setStartDate(date);
                                } else {
                                    if (!showUpgradeModal) {
                                        setUpgradeMessage(UpgradeSection.Filter_Customization);
                                        // setOpenFilterModal(false);
                                        setShowUpgradeModal(true);
                                    }
                                }
                            }}
                            className="h-10 w-full rounded-lg border-[#2F343F] bg-[#20242C] py-2 pl-8 text-xs font-light text-white"
                            placeholderText="DD/MM/YYYY"
                            maxDate={endDate ? endDate : new Date()}
                        />
                    </div>
                    <MagnifyingGlassIcon className="absolute bottom-0 left-[10px] top-0 m-auto h-4 w-4 text-[#7A869B]" />
                </div>
                <div className="flex h-[1px] w-3 items-center bg-white text-center text-xs font-light" />
                <div className="relative">
                    <div className="flex h-10 w-[150px] items-center">
                        <DatePicker
                            selected={endDate}
                            onChange={(date: Date) => {
                                if (userIsPro) {
                                    setEndDate(date);
                                } else {
                                    if (!showUpgradeModal) {
                                        setUpgradeMessage(UpgradeSection.Filter_Customization);
                                        // setOpenFilterModal(false);
                                        setShowUpgradeModal(true);
                                    }
                                }
                            }}
                            className="h-10 w-full rounded-lg border-[#2F343F] bg-[#20242C] py-2 pl-8 text-xs font-light text-white"
                            placeholderText="DD/MM/YYYY"
                            maxDate={new Date()}
                            minDate={startDate}
                        />
                    </div>
                    <MagnifyingGlassIcon className="absolute bottom-0 left-[10px] top-0 m-auto h-4 w-4 text-[#7A869B]" />
                </div>
            </div>
        </div>
    );
};

const TimeRangePicker = () => {
    const {
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        showUpgradeModal,
        setShowUpgradeModal,
        setUpgradeMessage,
    } = useFilterContext();

    // const { setOpenFilterModal } = useNewsFeedContext();
    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;
    return (
        <div className="flex w-full flex-col items-start gap-2 border-b-1 border-[#2F343F] px-4 py-3">
            <div className="flex w-full items-start justify-between gap-2 p-0">
                <p className="text-xs font-light text-[#A1ADC1]">By time</p>
                <p
                    onClick={() => {
                        if (userIsPro) {
                            setStartTime("");
                            setEndTime("");
                        } else {
                            if (!showUpgradeModal) {
                                setUpgradeMessage(UpgradeSection.Filter_Customization);
                                // setOpenFilterModal(false);
                                setShowUpgradeModal(true);
                            }
                        }
                    }}
                    className="cursor-pointer text-xs font-normal text-[#306CE8] hover:brightness-75"
                >
                    Clear
                </p>
            </div>
            <div className="filter-time-picker flex w-full items-center justify-between gap-2 p-0 text-xs font-light">
                <div className="flex h-10 w-[150px] items-center overflow-hidden rounded-lg border-1 border-[#2F343F] bg-[#20242C]">
                    <TimePicker
                        onChange={(e) => {
                            if (userIsPro) {
                                e && setStartTime(e?.toString());
                            } else {
                                if (!showUpgradeModal) {
                                    setUpgradeMessage(UpgradeSection.Filter_Customization);
                                    // setOpenFilterModal(false);
                                    setShowUpgradeModal(true);
                                }
                            }
                        }}
                        format="h:mm a"
                        value={startTime}
                        clockIcon={null}
                        disableClock={true}
                        clearIcon={null}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                    />
                </div>
                <div className="flex h-[1px] w-3 items-center bg-white" />
                <div className="flex h-10 w-[150px] items-center overflow-hidden rounded-lg border-1 border-[#2F343F] bg-[#20242C]">
                    <TimePicker
                        onChange={(e) => {
                            if (userIsPro) {
                                e && setStartTime(e?.toString());
                            } else {
                                if (!showUpgradeModal) {
                                    setUpgradeMessage(UpgradeSection.Filter_Customization);
                                    // setOpenFilterModal(false);
                                    setShowUpgradeModal(true);
                                }
                            }
                        }}
                        format="h:mm a"
                        value={endTime}
                        clockIcon={null}
                        disableClock={true}
                        clearIcon={null}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                    />
                </div>
            </div>
        </div>
    );
};

// function NonAssetSpecificNewsToggle() {
//     const { filterSet, setFilterSet } = useNewsFeedContext();
//     return (
//         <div className="flex w-full items-center justify-between gap-[10px] border-b-1 border-[#2F343F] bg-[#1C1F27] p-4">
//             <div className="flex items-center gap-1">
//                 <p className="whitespace-nowrap text-xs font-normal text-white">
//                     Hide All Non Asset Specific News
//                 </p>
//                 <Tooltip
//                     className="tooltip-custom-warning text-xs font-light"
//                     content="Enabling this filter will restrict the content shown in your feed by a significant amount. Any news that doesn't pertain to a particular cryptocurrency will be filtered out. We recommend using this filter only for custom feeds, where you want to see news exclusively about specific assets."
//                 >
//                     <ExclamationTriangleIcon className="h-4 w-4 text-[#FE455B]" />
//                 </Tooltip>
//             </div>
//             <Toggle
//                 checked={filterSet.hideAssetNews}
//                 onChange={() => {
//                     filterSet.hideAssetNews = !filterSet.hideAssetNews;
//                     setFilterSet(filterSet);
//                     saveFilterSetById({ filterSet, jwt: Cookies.get("stytch_session_jwt") });
//                 }}
//             />
//         </div>
//     );
// }

function NewsSources({
    filterSetChanged,
    setFilterSetChanged,
}: {
    filterSetChanged: boolean;
    setFilterSetChanged: Dispatch<SetStateAction<boolean>>;
}) {
    const { filterSet, setFilterSet } = useNewsFeedContext();

    return (
        <div className="flex w-full flex-col items-start gap-2 px-4 py-3">
            <p className="text-xs font-light text-[#A1ADC1]">Sources</p>
            {/* <div className="flex w-full items-center justify-between gap-[10px] px-0 py-1">
                <p className="text-xs font-normal text-white">Low Quality News</p>
                <Toggle checked={true} disabled={true} />
            </div> */}
            <div className="w-full border-b-1 border-[#2F343F]">
                <SourceToggle
                    filterSetChanged={filterSetChanged}
                    setFilterSetChanged={setFilterSetChanged}
                    filterSet={filterSet}
                    setFilterSet={setFilterSet}
                    sourceKey="showPrimarySourcesOnly"
                    label="Primary Sources Only"
                    icon={<CheckBadgeIcon className="h-4 w-4" color="#FFFFFF" />}
                />
            </div>

            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showBlockbeatNews"
                label="BlockBeat News"
                icon={<MemoLogo className="h-[10px] w-2" color="#FFFFFF" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showTwitterNews"
                label="X"
                icon={<MemoX className="h-4 w-4 rounded-full bg-black" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showRedditNews"
                label="Reddit"
                icon={<MemoReddit className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showNewsNews"
                label="News"
                icon={<MemoNews className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showPressRelease"
                label="Press Release"
                icon={<MemoSpeaker className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showGovernmentPress"
                label="Government Press"
                icon={<MemoGavel className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showBlogNews"
                label="Blog"
                icon={<MemoCryptonews className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showYouTubeNews"
                label="YouTube"
                icon={<MemoYouTube className="h-4 w-4" />}
            />
            <SourceToggle
                filterSetChanged={filterSetChanged}
                setFilterSetChanged={setFilterSetChanged}
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                sourceKey="showPodcastNews"
                label="Podcast"
                icon={<MemoPodcast className="h-4 w-4" />}
            />
        </div>
    );
}

function SourceToggle({
    sourceKey,
    label,
    icon,
    filterSet,
    setFilterSet,
    filterSetChanged,
    setFilterSetChanged,
}: {
    sourceKey: string;
    label: string;
    icon?: JSX.Element;
    filterSet: FilterSet;
    setFilterSet: Dispatch<SetStateAction<FilterSet>>;
    filterSetChanged: boolean;
    setFilterSetChanged: Dispatch<SetStateAction<boolean>>;
}) {
    const { filterSets, setFilterSets } = useNewsFeedContext();
    const { showUpgradeModal, setShowUpgradeModal, setUpgradeMessage } = useFilterContext();

    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    if (!filterSet) return null;

    return (
        <div className="flex w-full items-center justify-between gap-[10px] px-0 py-1">
            <div className="flex items-center gap-2">
                {icon && (
                    <div
                        className={`flex h-4 w-4 items-center justify-center rounded-full ${
                            sourceKey === "showYouTubeNews" ? "" : "bg-[#306CE8]"
                        }`}
                    >
                        {icon}
                    </div>
                )}
                <div className="flex gap-4 text-xs font-normal text-white">
                    <p>{label}</p>
                    {(sourceKey === "showBlogNews" ||
                        sourceKey === "showYouTubeNews" ||
                        sourceKey === "showPodcastNews") && (
                        <span className="text-[#A1ADC1]">Coming Soon</span>
                    )}
                </div>
            </div>
            <Toggle
                checked={filterSet?.[sourceKey] || false}
                onChange={() => {
                    if (userIsPro) {
                        setFilterSetChanged(!filterSetChanged);
                        filterSet[sourceKey] = !filterSet[sourceKey];
                        if (sourceKey === "showPrimarySourcesOnly") {
                            if (!filterSet[sourceKey]) searchProxy.primarySourcesOnly = true;
                            else searchProxy.primarySourcesOnly = false;
                        }
                        if (filterSet.filterName !== "Saved") {
                            // this is useful so we can use use source toggle buttons for saved news also
                            setFilterSets(uniqBy([...filterSets, filterSet], "filterIndex"));
                            setFilterSet(filterSet);
                        } else {
                            // this is useful so we can use use source toggle buttons for saved news also
                            // HACK: also, since the customized filterset is what is being used for this, it is simply returned so the state is updated.
                            setFilterSets(uniqBy([...filterSets], "filterIndex"));
                        }
                    } else {
                        if (!showUpgradeModal) {
                            setUpgradeMessage(UpgradeSection.Filter_Customization);
                            // setOpenFilterModal(false);
                            setShowUpgradeModal(true);
                        }
                    }
                }}
                disabled={
                    sourceKey === "showBlogNews" ||
                    sourceKey === "showYouTubeNews" ||
                    sourceKey === "showPodcastNews"
                }
            />
        </div>
    );
}

// const Coins: React.FC = () => {
//     const { filterSet, setFilterSet } = useNewsFeedContext();
//     const { coinOptions, setPage } = useFilterContext();

//     return (
//         <div className="flex w-full flex-col items-start gap-3 px-4 pb-8 pt-3">
//             <div className="flex w-full items-start justify-between gap-2 p-0">
//                 <p className="text-xs font-light text-[#A1ADC1]">Coins</p>
//             </div>
//             <div className="flex w-full flex-col items-start gap-3 p-0">
//                 <div className="flex w-full justify-between gap-[10px] p-0">
//                     <RadioInput
//                         className="flex-col gap-3"
//                         defaultSelected={coinOptions?.find((o) => o.name === filterSet.coinFilter)}
//                         onChange={(e: { name: string }) => {
//                             filterSet.coinFilter = e.name;
//                             setFilterSet(filterSet);
//                             saveFilterSetById({ filterSet, jwt: Cookies.get("stytch_session_jwt") });
//                         }}
//                     >
//                         {coinOptions}
//                     </RadioInput>
//                     <div className="flex flex-col gap-3">
//                         {coinOptions?.map((item, i) => (
//                             <div
//                                 key={i}
//                                 onClick={() => setPage(item.name)}
//                                 className="flex h-6 w-6 cursor-pointer items-center justify-center hover:brightness-75"
//                             >
//                                 <ChevronRightIcon className="h-[13px] w-[11px]" />
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//                 {/* TODO: disabled for now */}
//                 {/* <div className="flex w-full cursor-pointer items-center gap-[2px] p-0 hover:brightness-75">
//                     <div className="flex h-4 w-4 items-center justify-center">
//                          <PlusIcon className="h-2 w-2" color="#A1ADC1" />;
//                     </div>
//                     <p className="text-xs font-normal text-[#A1ADC1]">Add new Watchlist</p>
//                 </div> */}
//             </div>
//         </div>
//     );
// };

function FilterActions() {
    const { filterSet, setFilterSet, filterSets, setFilterSets, setTimeRange } = useNewsFeedContext();

    const {
        setStartDate,
        setEndDate,
        setStartTime,
        setEndTime,
        setShowConfirmCloneWatchlistModal,
        showUpgradeModal,
        setShowUpgradeModal,
        showSaveButton,
        setUpgradeMessage,
    } = useFilterContext();

    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    function updateFilterSet(filterName: string) {
        if (filterName === "No Socials") {
            const keys = Object.keys(filterSet);

            // Set keys starting with "show" to true
            const showKeys = keys.filter((key) => key.startsWith("show"));
            showKeys.forEach((key) => (filterSet[key] = true));

            // Set keys containing substrings from substringsToSetFalse to false
            const substringsToSetFalse = ["Medium", "Twitter", "Reddit", "Podcast", "YouTube"];
            showKeys.forEach((key) => {
                const shouldSetFalse = substringsToSetFalse.some((substring) => key.includes(substring));
                if (shouldSetFalse) {
                    filterSet[key] = false;
                }
            });
        } else {
            const keys = Object.keys(filterSet);

            // Filter the keys that start with "show"
            const showKeys = keys.filter((key) => key.startsWith("show"));
            showKeys.forEach((key) => (filterSet[key] = true));
        }
        filterSet["showPrimarySourcesOnly"] = false;
        setFilterSets(uniqBy([...filterSets, filterSet], "filterIndex"));
        setFilterSet(filterSet);
    }

    return (
        <div className="flex w-full items-center justify-between gap-4 p-0">
            <div
                onClick={() => {
                    if (userIsPro) {
                        setStartDate(null);
                        setEndDate(null);
                        setStartTime("");
                        setEndTime("");
                        setTimeRange([]);
                        updateFilterSet(filterSet.filterName);
                        // setOpenFilterModal(false);
                    } else {
                        if (!showUpgradeModal) {
                            setUpgradeMessage(UpgradeSection.Filter_Customization);
                            // setOpenFilterModal(false);
                            setShowUpgradeModal(true);
                        }
                    }
                    showSaveButton(false);
                }}
                className="flex items-center gap-[2px]"
            >
                <XCircleIcon className="h-4 w-4 text-[#7A869B]" />
                <p className="cursor-pointer text-xs font-normal text-[#A1ADC1] hover:brightness-75">
                    Reset Filter Set
                </p>
            </div>
            <div
                className="flex items-center gap-[2px]"
                onClick={() => {
                    if (userIsPro) {
                        setShowConfirmCloneWatchlistModal(true);
                    } else {
                        if (!showUpgradeModal) {
                            setUpgradeMessage(UpgradeSection.Filter_Creation);
                            // setOpenFilterModal(false);
                            setShowUpgradeModal(true);
                        }
                    }
                }}
            >
                <div className="flex h-4 w-4 items-center justify-center">
                    <PlusIcon className="h-3 w-3 stroke-2 text-[#7A869B]" />
                </div>
                <p className="cursor-pointer text-xs font-normal text-[#A1ADC1] hover:brightness-75">
                    Save as new filter Set
                </p>
            </div>
        </div>
    );
}

function ShowResultsButton() {
    const { setOpenFilterModal } = useNewsFeedContext();
    return (
        <Button
            type="submit"
            onClick={() => setOpenFilterModal(false)}
            className="h-10 w-full items-center justify-center gap-[10px] rounded-lg border-none bg-[#306CE8] px-4 py-3"
        >
            <p className="text-xs font-normal text-white">Done</p>
        </Button>
    );
}
