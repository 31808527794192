import { SVGProps, memo } from "react";

function Cryptonews(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} rx={8} fill={props.color || "#00DDB3"} />
            <path
                d="M5.668 5.711a1.254 1.254 0 00-.847.808L3 11.984l.288.289L6.2 9.34c-.04-.122-.093-.259-.093-.403a.938.938 0 11.535.845L3.71 12.695l.305.305 5.465-1.821a1.22 1.22 0 00.808-.847l.835-2.957-2.482-2.483-2.973.819zm7.057-1.262L11.55 3.275a.94.94 0 00-1.328 0L9.12 4.38l2.483 2.483 1.105-1.103a.902.902 0 00.018-1.311z"
                fill={props.color || "#fff"}
            />
        </svg>
    );
}

const MemoCryptonews = memo(Cryptonews);
export default MemoCryptonews;
