import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { ArrowUpIcon } from "@heroicons/react/24/solid";

export default function ScrollToTopButton() {
    const { showScrollToTopButton, setScrollToTop } = useNewsFeedContext();

    if (!showScrollToTopButton) return <></>;

    return (
        <div className="absolute top-16 z-0 flex w-full justify-center">
            <div
                className={`to pointer-events-auto flex h-10 cursor-pointer items-center justify-between gap-2 rounded-3xl bg-[#306CE8] py-1 pl-1 pr-4 hover:animate-none`}
                onClick={() => setScrollToTop(true)}
            >
                <div className="pointer-events-auto flex h-8 w-8 items-start gap-2 border-r-1 border-[#4479E6] p-2">
                    <ArrowUpIcon className="h-4 w-4 text-white" />
                </div>
                <div className="flex flex-col items-start justify-center p-0">
                    <p className="text-xs text-white">Back To Top</p>
                </div>
            </div>
        </div>
    );
}
