/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import NewsListItem from "@app/dashboard/news-feed/news-list/NewsListItem";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useRouter, useSelectedLayoutSegment } from "next/navigation";
import { News } from "@/types/api";
// import { fetchNewsItem } from "@/services/news";
import { isEmpty } from "lodash";

const ListItemNoTransition = ({ index, style, data }: { index: number; style; data: News[] }) => {
    const { display } = useNewsFeedContext();
    const item = data[index];

    return (
        <div
            key={item.slug}
            className={`list-item ${
                index !== data.length - 1 && "border-b-1 border-[#252932]"
            } hover:brightness-90`}
            style={{ ...style, height: display === "normal" ? 80 : 50 }}
        >
            <NewsListItem index={index} article={item} />
        </div>
    );
};

const InfiniteNewsList = () => {
    const {
        display,
        showScrollToTopButton,
        setShowScrollToTopButton,
        scrollToTop,
        setScrollToTop,
        newsItem,
        newsIndex,
        setNewsItem,
        renderedResults,
        loadMoreItems,
    } = useNewsFeedContext();

    const router = useRouter();

    // const [renderedItems, setRenderedItems] = useState([...items]);

    const isItemLoaded = useCallback((index) => !!renderedResults[index], [renderedResults]);

    const segment = useSelectedLayoutSegment();

    const listRef = useRef(null as any);

    // check the function below

    const handleScroll = useCallback(
        (e) => {
            if (e.scrollOffset > 820) {
                if (!showScrollToTopButton) {
                    return setShowScrollToTopButton(true);
                }
            } else {
                if (showScrollToTopButton) {
                    return setShowScrollToTopButton(false);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showScrollToTopButton],
    );

    const scrollToTopOfFeed = () => {
        // const next = renderedResults[0];
        // if (next) {
        //     router.push(`/dashboard/${next.slug}`);
        // }
        listRef?.current?._listRef?.scrollToItem(0);
        setScrollToTop(false);
    };

    const scrollToIndex = (index: number) => {
        listRef?.current?._listRef?.scrollToItem(index);
        setTimeout(() => {
            const desiredItem = renderedResults.find(
                (item: News) => item.slug === decodeURIComponent(segment as string),
            );
            return setNewsItem(desiredItem as News);
        }, 1000);
    };

    // const fetchNewsBySlug = async () => {
    //     const res = await fetchNewsItem(decodeURIComponent(segment as string) as string);
    //     if (!isEmpty(res)) {
    //         // const existingNews = renderedItems;
    //         const newSet = [...renderedItems, res];

    //         const sortedNews = sortBy(newSet, "publishedAt").reverse();

    //         const index = sortedNews.findIndex(
    //             (item: News) => item.slug === decodeURIComponent(segment as string),
    //         );

    //         setRenderedItems([...sortedNews]);
    //         if (index >= 0) {
    //             listRef?.current?._listRef?.scrollToItem(index);
    //         }
    //         setTimeout(() => {
    //             return setNewsItem(res);
    //         }, 1000);
    //     }
    // };

    useEffect(() => {
        if (scrollToTop) {
            return scrollToTopOfFeed();
        }

        if (!isEmpty(segment)) {
            if (isEmpty(newsItem)) {
                let index = renderedResults.findIndex(
                    (item: News) => item.slug === decodeURIComponent(segment as string),
                );

                if (index >= 0) {
                    if (index + 4 <= renderedResults.length) {
                        index = index + 4;
                    }
                    scrollToIndex(index);
                    return;
                }
                // if the newsItem is not on the list
                // fetchNewsBySlug();
            }
        }
    }, [renderedResults, scrollToTop]);

    return (
        <div
            className="h-full w-full overflow-hidden"
            tabIndex={1}
            onKeyDown={(e) => {
                if (e.key != "ArrowDown" && e.key != "ArrowUp") {
                    return;
                }
                const offset = e.key == "ArrowDown" ? 1 : -1;
                const next = renderedResults[newsIndex + offset];
                if (next) {
                    router.push(`/dashboard/${next.SK}`);
                    listRef?.current?._listRef?.scrollToItem(newsIndex + offset, "auto");
                }
            }}
        >
            {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment 
            // @ts-ignore */}
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={renderedResults.length + 10}
                        loadMoreItems={loadMoreItems}
                        ref={listRef}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <FixedSizeList
                                    itemKey={(index, data) =>
                                        `${data[index].slug}-${data[index].read ? "r" : "u"}-${index}`
                                    }
                                    className="scrollbar-hide rounded-b-lg"
                                    height={height}
                                    itemCount={renderedResults.length}
                                    itemSize={display === "normal" ? 80 : 50}
                                    width={width}
                                    itemData={renderedResults}
                                    onItemsRendered={onItemsRendered}
                                    ref={ref}
                                    onScroll={(e) => handleScroll(e)}
                                >
                                    {ListItemNoTransition}
                                </FixedSizeList>
                            );
                        }}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </div>
    );
};

export default InfiniteNewsList;
