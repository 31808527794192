import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";

export default function HourlyMarketInsights() {
    // insights will come in an array
    const content = [
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
        {
            heading: "Bitcoin ETF Approval",
            body: "Bitcoin ETF approval signals a potential influx of institutional investment and may open new opportunities for growth in the crypto market.",
            sentiment: MemoSmilingEmoji,
        },
    ];

    return (
        <div className="relative flex h-1/2 w-full grow flex-col overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F26]">
            <div className="flex h-11 w-full justify-between border-b-1 border-[#252932] px-2 py-[6px]">
                <p className="flex items-center text-[14px] font-normal leading-6">Hourly Market Insights</p>
                <div className="flex items-center gap-1">
                    <MemoLogo className="w-2" />
                    <p className="text-[9px] font-light leading-3 text-[#7A869B]">BlockBeat AI</p>
                    <ExclamationCircleIcon className="h-2 w-2 text-[#505A68]" />
                </div>
            </div>

            <div className="relative flex h-[calc(100%-44px)] w-full flex-col text-sm">
                <div className="flex w-full items-center justify-center gap-3 border-b-1 border-[#272C34] py-2 text-[10px] font-light leading-5 text-[#505A68]">
                    <p>Last Update: 3PM</p>
                    <p> | </p>
                    <p>Next Update: In 2h 43m 23s</p>
                </div>
                <div className="scrollbar-hide mb-12 flex h-[calc(100%-87.5px)] w-full flex-col overflow-y-scroll px-2 py-1 font-light text-[#A1ADC1]">
                    <div className="news-detail-gradient absolute bottom-0 left-0 h-40 w-full" />
                    <div className="mb-36 list-disc space-y-2 pt-[6px] font-light text-[#A1ADC1]">
                        {content.map((c, i) => (
                            <div className="flex gap-2" key={c.heading}>
                                <p className="text-sm font-bold">{i + 1}.</p>
                                <p className="text-[14px] font-normal leading-5">
                                    <span className="text-sm font-bold">{c.heading}: </span>
                                    {c.body}
                                    <span className="inline-block">
                                        <c.sentiment className="h-4 w-4" />
                                    </span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="absolute bottom-[5px] flex items-center overflow-scroll bg-[#252932] p-2 py-2 text-[10px] font-light leading-5 text-[#505A68]">
                    <div className="marquee flex w-full items-center gap-2">
                        <p className="whitespace-nowrap">Last Update: Yesterday at 7PM EST</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Next Update: In 2h 43m 23s</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Last Update: Yesterday at 7PM EST</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Next Update: In 2h 43m 23s</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Last Update: Yesterday at 7PM EST</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Next Update: In 2h 43m 23s</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Last Update: Yesterday at 7PM EST</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Next Update: In 2h 43m 23s</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Last Update: Yesterday at 7PM EST</p>
                        <p className="whitespace-nowrap"> | </p>
                        <p className="whitespace-nowrap">Next Update: In 2h 43m 23s</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
