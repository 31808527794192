import { useState } from "react";
import Modal from "@components/Modal";
// import useLocalStorageState from "use-local-storage-state";
import LandingPage from "@app/dashboard/news/LandingPage";
import Customization from "@app/dashboard/news/Customization";
import Top50 from "@app/dashboard/news/Top50";
import Top100 from "@app/dashboard/news/Top100";
import Top200 from "@app/dashboard/news/Top200";
import Top500 from "@app/dashboard/news/Top500";
import Top1000 from "@app/dashboard/news/Top1000";
// import WatchlistFilter from "@app/dashboard/news/WatchlistFilter";
import AllCoins from "@app/dashboard/news/AllCoins";
// import { DeleteWatchlistModal } from "@/components/watchlist/DeleteWatchlistModal";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useFilterContext } from "@app/dashboard/news/FilterContext";
import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import { ConfirmCloneWatchlistModal } from "@app/dashboard/news/ConfirmCloneWatchlistModal";
import { ConfirmEditFilterSetModal } from "@app/dashboard/news/ConfirmEditFilterSetModal";
import { FilterSet } from "@/types/util";
import { UpgradeModal } from "../news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";

export const FilterModal = ({ assets, handles }: { assets; handles }) => {
    const { openFilterModal, setOpenFilterModal } = useNewsFeedContext();
    const {
        showUpgradeModal,
        setShowUpgradeModal,
        showConfirmCloneWatchlistModal,
        setShowConfirmCloneWatchlistModal,
        upgradeMessage,
    } = useFilterContext();

    const [twitterUsername, setTwitterUsername] = useState("");
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [redditUsername, setRedditUsername] = useState("");
    const [page, setPage] = useState(CUSTOMIZE.LANDING_PAGE);
    const [tab, setTab] = useState("Twitter");
    const [showEditModal, setShowEditModal] = useState(false);
    const [filterSetEdited, setFilterSetEdited] = useState(null as unknown as FilterSet);

    const customization_tabs = ["Twitter", "Reddit", "News", "Press Release", "Medium", "Blog"];

    const twitterSources = [
        {
            name: "my sources",
            accounts: handles.mine?.map((h) => ({ name: h, status: true })),
        },
        {
            name: "blockbeat sources",
            accounts: handles.blockbeat?.map((h) => ({ name: h, status: true })),
        },
    ];

    const redditSources = [
        {
            name: "my sources",
            accounts: [
                {
                    name: "r/MDudas",
                    status: true,
                },
                {
                    name: "r/PaikCapital",
                    status: true,
                },
                {
                    name: "r/ErikVoorhees",
                    status: true,
                },
                {
                    name: "r/IvanOnTech",
                    status: true,
                },
                {
                    name: "r/Bybit_Official",
                    status: true,
                },
            ],
        },
        {
            name: "blockbeat sources",
            accounts: [
                {
                    name: "r/Bitcoin",
                    status: true,
                },
                {
                    name: "r/Ethereum",
                    status: true,
                },
                {
                    name: "r/aantonop",
                    status: true,
                },
                {
                    name: "r/Bybit_Official",
                    status: true,
                },
                {
                    name: "r/APompliano",
                    status: true,
                },
                {
                    name: "r/ErikVoorhees",
                    status: false,
                },
                {
                    name: "r/VitalikButerin",
                    status: true,
                },
                {
                    name: "r/IvanOnTech",
                    status: true,
                },
                {
                    name: "r/MessariCrypto",
                    status: false,
                },
                {
                    name: "r/TheCryptoDog",
                    status: true,
                },
                {
                    name: "r/MDudas",
                    status: true,
                },
                {
                    name: "r/PaikCapital",
                    status: true,
                },
                {
                    name: "r/SushiSwap",
                    status: false,
                },
                {
                    name: "r/Saylor",
                    status: true,
                },
                {
                    name: "r/girlgone_crypto",
                    status: true,
                },
            ],
        },
    ];

    // const [index, setIndex] = useState(0);

    // const [showDeleteModal, setShowDeleteModal] = useState(false);

    // const [currentWatchlist, setCurrentWatchlist] = useState(
    //     {} as {
    //         name: string;
    //         assets: any[];
    //     },
    // );

    // useEffect(() => {
    //     const desiredWatchlist = watchlists?.find((watchlist) => watchlist.name === page);
    //     if (desiredWatchlist) {
    //         const index = watchlists?.findIndex((watchlist) => watchlist?.name === page);
    //         setCurrentWatchlist(desiredWatchlist);
    //         setIndex(index);
    //     }
    // }, [page]);

    return (
        <div>
            {/* <DeleteWatchlistModal
                    index={index}
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    watchlist={currentWatchlist!}
                /> */}

            {showConfirmCloneWatchlistModal && (
                <ConfirmCloneWatchlistModal
                    showConfirmCloneWatchlistModal={showConfirmCloneWatchlistModal}
                    setShowConfirmCloneWatchlistModal={setShowConfirmCloneWatchlistModal}
                />
            )}
            {showEditModal && (
                <ConfirmEditFilterSetModal
                    show={showEditModal}
                    setShow={setShowEditModal}
                    filterSet={filterSetEdited}
                />
            )}
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={upgradeMessage}
            >
                <></>
            </UpgradeModal>
            <Modal
                show={openFilterModal}
                setShow={setOpenFilterModal}
                showCloseButton={false}
                right
                className="h-screen max-h-screen w-80 max-w-[360px] rounded-none"
                style={{
                    zIndex: 2,
                    // this is so the modal that shows when the userIsNotPro can be above it
                }}
            >
                {page === CUSTOMIZE.LANDING_PAGE && (
                    <LandingPage
                        setFilterSetEdited={setFilterSetEdited}
                        setShowEditModal={setShowEditModal}
                    />
                )}

                {page === CUSTOMIZE.CUSTOMIZATION && (
                    <Customization
                        tab={tab}
                        setTab={setTab}
                        twitterUsername={twitterUsername}
                        setTwitterUsername={setTwitterUsername}
                        redditUsername={redditUsername}
                        setRedditUsername={setRedditUsername}
                        customization_tabs={customization_tabs}
                        setPage={setPage}
                        twitterSources={twitterSources}
                        redditSources={redditSources}
                    />
                )}

                {page === CUSTOMIZE.ALL_COINS && <AllCoins setPage={setPage} assets={assets} />}

                {page === CUSTOMIZE.TOP_50 && <Top50 setPage={setPage} assets={assets} />}

                {page === CUSTOMIZE.TOP_100 && <Top100 setPage={setPage} assets={assets} />}

                {page === CUSTOMIZE.TOP_200 && <Top200 setPage={setPage} assets={assets} />}

                {page === CUSTOMIZE.TOP_500 && <Top500 setPage={setPage} assets={assets} />}

                {page === CUSTOMIZE.TOP_1000 && <Top1000 setPage={setPage} assets={assets} />}

                {/* {page === currentWatchlist?.name && (
                        <WatchlistFilter
                            setShowFilterModal={setOpenFilterModal}
                            setPage={setPage}
                            setShowDeleteModal={setShowDeleteModal}
                            watchlistNameFrom={currentWatchlist?.name}
                            assets={assets}
                        />
                    )} */}
            </Modal>
        </div>
    );
};
