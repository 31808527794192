import filterSetList from "@/store/filterSetStore";
import userPaymentState from "@/store/userPaymentStore";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSnapshot } from "valtio";

export function FilterConfigureButton() {
    const { setOpenFilterModal, setFilterSet, filterSets, filterSet } = useNewsFeedContext();

    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    const { data: fil } = useSnapshot(filterSetList);

    const filInitial = fil.find((f) => f.filterIndex === filterSet.filterIndex);
    const [difference, setDifference] = useState(false);

    if (filInitial) {
        let anyDifference = false;
        Object.keys(filInitial).forEach((key) => {
            if (key in filterSet && filInitial[key] !== filterSet[key]) {
                anyDifference = true;
            }
        });

        if (anyDifference && !difference) {
            setDifference(true);
        } else if (!anyDifference && difference) {
            setDifference(false);
        }
    }

    return (
        <button
            onClick={() => {
                if (userIsPro) {
                    if (filterSet.filterName !== "Saved") {
                        // If saved is not the one currently being used before the filterset button is clicked
                        const currentFIltersetIsCustom = filterSets.find(
                            (fil) => fil.filterName === filterSet.filterName,
                        );

                        if (currentFIltersetIsCustom) {
                            // If the filterset viewed before click event is one of the custom ones for the user
                            setFilterSet(currentFIltersetIsCustom);
                        } else {
                            const customized = filterSets.find((f) => f.filterName === "Customized");
                            if (customized) setFilterSet(customized);
                        }
                    }
                }
                setOpenFilterModal(true);
            }}
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-[#252932] hover:brightness-75"
        >
            <FunnelIcon
                className={`h-[14px] w-[14px] ${
                    filterSet.filterName !== "All News" &&
                    !difference &&
                    filterSet.filterName !== "Customized"
                        ? "text-[#306CE8]"
                        : "text-[#7A869B]"
                }`}
                strokeWidth={2.5}
            />
        </button>
    );
}
