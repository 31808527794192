"use client";

export default function Checkbox({
    disabled,
    onChange,
    className,
    checked,
}: {
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    checked?: boolean;
}) {
    return (
        <div className="form-control">
            <input
                type="checkbox"
                className={`${className} checkbox border-[#3D4552] bg-[#20242C]`}
                onChange={onChange}
                checked={checked}
                disabled={disabled ? true : false}
            />
        </div>
    );
}
