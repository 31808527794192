import { DISPLAY, News } from "@/types/api";
import { concat, isEmpty, isEqual, isNumber, uniq, uniqBy } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { fetchMyFilterSets, fetchReadList, fetchVoteList } from "@/services/news";
import Cookies from "js-cookie";
import useNews from "@app/dashboard/news-feed/useNews";
import { useCopyToClipboard, useList, useToggle } from "react-use";
import { FilterSet, NewsFeedContextValue, activeFilters, defaultFilterSets } from "@/types/util";
import { useStytchSession } from "@stytch/nextjs";
import {
    fetchBookmarkedNews,
    fetchNewsByIconFilter,
    searchByTagsPayload,
    searchNewsByTags,
    searchTagsAcrossFields,
} from "@/services/search";
import { filterSetFilter, generateStructuredString } from "@app/dashboard/news-feed/functions";
import { SearchPayload, searchProxy } from "@/store/searchStore";
import { useSnapshot } from "valtio";
import { isBefore, parseISO } from "date-fns";
import { fetchBookmarkedList } from "@/services/bookmarks";
import bookmarkedListState from "@/store/bookmarkStore";
import { userProxy } from "@/store/userStore";
import userPaymentState from "@/store/userPaymentStore";
import { fetchMyWatchlists } from "@/services/watchlists";
import watchlistState from "@/store/watchlistStore";
import filterSetList from "@/store/filterSetStore";

const NewsFeedContext = createContext<NewsFeedContextValue>({} as NewsFeedContextValue);

// Update NewsFeedContextProvider component
const NewsFeedContextProvider: React.FC<any> = ({ children, staticNews }) => {
    const allBookmarks = useSnapshot(bookmarkedListState);
    const [display, setDisplay] = useState<DISPLAY>(DISPLAY.NORMAL);
    // const [pause, setPause] = useState<boolean>(false);
    // const [count, setCount] = useState<number>(0);
    const [filterSetChanged, setFilterSetChanged] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
    const [showScrollToTopButton, setShowScrollToTopButton] = useState<boolean>(false);
    const [scrollToTop, setScrollToTop] = useState<boolean>(false);
    const [filterSets, setFilterSets] = useState<FilterSet[]>(defaultFilterSets);
    const [filterSet, setFilterSet] = useState<FilterSet>(defaultFilterSets[0]);
    const { session } = useStytchSession();
    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;
    const { realTimeNews } = useNews();
    const [renderedNews, setRenderedNews] = useState<News[]>(staticNews);
    const [showResultsBanner, setShowResultsBanner] = useState(false);

    const [newsIndex, setNewsIndex] = useState<number>(0);
    const [timeRange, setTimeRange] = useState<number[] | []>([]);
    const [newsItem, setNewsItem] = useState({} as News);
    const [copyValue, copy] = useCopyToClipboard();

    const [showAlert, setShowAlert] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showSearchNewslist, setShowSearchNewslist] = useState(false);
    // const [payload, setPayload] = useState({} as searchByTagsPayload);
    const [fieldsPayload, setFieldsPayload] = useState({} as searchByTagsPayload);
    const [searchQuery, setSearchQuery] = useState([] as string[]);
    // const [fieldsSearchQuery, setFieldsSearchQuery] = useState([] as string[]);
    const [searchQueryCategory, setSearchQueryCategory] = useState([] as string[]);
    const neededFilterSet = activeFilters.filter((item) => filterSetFilter(filterSet, item));
    const structuredString = generateStructuredString(...neededFilterSet);
    const encodedUrl = encodeURIComponent(structuredString);
    const [searchNewsList, setSearchNewsList] = useState([] as any[]);
    const [loading, setLoading] = useState(false);
    const [newsLoading, setNewsLoading] = useState(false);
    const searchStore = useSnapshot(searchProxy) as SearchPayload;
    const [moreSearchedNews, { push: pushNewSearchedNews, set: setNewSearchedNews }] = useList([] as News[]);
    const [hideResults, setHideResults] = useToggle(true);
    const [moreNews, { push, set }] = useList([] as News[]);
    const [newsLengthFromSource, setNewsLengthFromSource] = useState(0);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    const filterForLast7Days = userIsPro ? false : true;

    const [myReadList, { set: setReadList }] = useList([] as any[]);

    useEffect(() => {
        if (session) {
            const jwt = Cookies.get("stytch_session_jwt") as string;

            fetchReadList(jwt).then((rl) => {
                setReadList(rl);
            });

            fetchBookmarkedList(jwt).then((bm) => {
                bookmarkedListState.data = bm.bookmarks;
            });

            fetchMyWatchlists(jwt).then((wl) => {
                watchlistState.data = wl.watchlists;
            });

            fetchMyFilterSets(jwt).then((f: FilterSet[]) => {
                const newF = f.map((fi) => {
                    if (Object.keys(fi).includes("showPrimarySourcesOnly")) return fi;

                    return {
                        ...fi,
                        showPrimarySourcesOnly: false,
                    };
                });
                filterSetList.data = newF;
                setFilterSets(f as FilterSet[]);
            });

            fetchVoteList(jwt).then((vl) => {
                Object.keys(vl).forEach((k) => (userProxy.votes[k] = vl[k]));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    useEffect(() => {
        if (filterSet.filterName === "Saved") {
            setNewsLoading(true);
            set([]);
            setSearchNewsList([]);

            if (!isEmpty(encodedUrl)) {
                fetchBookmarkedNews({
                    filter: encodedUrl,
                    time: timeRange,
                    primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                })
                    .then((res) => {
                        if (isEmpty(res)) {
                            setNewsLengthFromSource(0);
                        } else {
                            setNewsLengthFromSource(isNumber(res.total) ? res.total : res.total.value);
                        }
                        const results = res.results;
                        setRenderedNews([...results]);
                        setNewsLoading(false);
                        return;
                    })
                    .catch(() => {
                        setRenderedNews([]);
                        setNewsLoading(false);
                    });
            } else {
                setRenderedNews([]);
                setNewsLoading(false);
            }
        } else {
            if (showSearchNewslist) {
                if (!isEmpty(encodedUrl)) {
                    doSearch();
                } else setSearchNewsList([]);
            } else {
                setNewsLoading(true);
                set([]);
                setSearchNewsList([]);
                if (!isEmpty(encodedUrl)) {
                    setNewsLoading(true);

                    fetchNewsByIconFilter({
                        filter: encodedUrl,
                        time: timeRange,
                        primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                    })
                        .then((res) => {
                            const results: any[] = res.results;
                            setRenderedNews(results);
                            setNewsLoading(false);
                            setNewsLengthFromSource(isNumber(res.total) ? res.total : res.total.value);
                            return;
                        })
                        .catch(() => {
                            setRenderedNews([]);
                            setNewsLoading(false);
                        });
                } else {
                    setRenderedNews([]);
                    setNewsLoading(false);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encodedUrl, showSearchNewslist, filterSet, timeRange, filterSet.showPrimarySourcesOnly]);

    useEffect(() => {
        if (!showSearchNewslist) {
            if (realTimeNews.length > 0) {
                if (isEqual(neededFilterSet, activeFilters)) {
                    setRenderedNews([...realTimeNews, ...renderedNews]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realTimeNews.length, encodedUrl]);

    let myNews: News[] = [];

    if (!isEmpty(renderedNews)) {
        myNews = renderedNews.map((news) => ({
            ...news,
            read: uniq(myReadList).includes(news.SK),
            publishedAt: news?.publishedAt ? news.publishedAt : new Date(news?.NK as number).toISOString(), //2023-07-19T10:55:53.813Z
            slug: news?.slug ? news.slug : (news.SK as string),
            bookmarked: uniq(allBookmarks.data).includes(news.SK as string),
        }));
    }

    const mySearchedNews = searchNewsList.map((news) => ({
        ...news,
        read: uniq(myReadList).includes(news.SK),
        publishedAt: new Date(news.NK).toISOString(),
        slug: news.SK,
        bookmarked: uniq(allBookmarks.data).includes(news.SK as string),
    }));

    let filteredNews = concat(myNews, moreNews);

    if (showSearchNewslist) {
        filteredNews = concat(mySearchedNews, moreSearchedNews);
    }

    // defensive code
    const uniqueNews = uniqBy(filteredNews as News[], "slug");

    const resultsbeforeToday = uniqueNews.filter((news) => isBefore(parseISO(news.publishedAt), Date.now()));

    // for now, Disable EIN Press as its the wrong feed - requested by Isaac on 23/04/2024
    const renderedResults = resultsbeforeToday.filter((news) => news.source !== "EIN");

    const doSearch = async (searchPayload?: any) => {
        searchProxy.from = 0;
        searchProxy.filterForLast7Days = filterForLast7Days;
        // searchProxy.time = timeRange;

        setScrollToTop(true);
        setNewsLoading(true);
        setNewSearchedNews([]);

        try {
            const res = await searchNewsByTags(neededFilterSet, {
                ...(searchPayload || searchStore),
                primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                time: timeRange,
                from: 0,
            });
            searchProxy.total = res.total;
            setSearchNewsList(res?.results || []);
            setShowSearchNewslist(true);
        } catch (e) {
            // noop
        } finally {
            setHideResults(true);
            setLoading(false);
            setNewsLoading(false);
        }
    };

    const loadMoreItems = async () => {
        if (filterSet.filterName === "Saved") {
            const newsLength = moreNews.length + renderedNews.length;

            const moreOldNews = await fetchBookmarkedNews({
                filter: encodedUrl,
                time: timeRange,
                primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                from: newsLength,
            });

            if (moreOldNews?.results?.length > 0) {
                const retrievedOldNews = moreOldNews.results.map((news) => ({
                    ...news,
                    slug: news.SK,
                    read: uniq(myReadList).includes(news.SK),
                    publishedAt: new Date(news?.NK as number).toISOString(),
                    bookmarked: uniq(allBookmarks.data).includes(news.SK),
                }));
                return retrievedOldNews.forEach((item) => push(item));
            }
        } else {
            if (!showSearchNewslist) {
                const newsLength = moreNews.length + renderedNews.length;
                const moreOldNews = await fetchNewsByIconFilter({
                    filter: encodedUrl,
                    from: newsLength,
                    time: timeRange,
                    primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                });

                if (moreOldNews?.results?.length > 0) {
                    const retrievedOldNews = moreOldNews.results.map((news) => ({
                        ...news,
                        slug: news.SK,
                        read: uniq(myReadList).includes(news.SK),
                        publishedAt: new Date(news?.NK as number).toISOString(),
                        bookmarked: uniq(allBookmarks.data).includes(news.SK),
                    }));
                    return retrievedOldNews.forEach((item) => push(item));
                }
            } else {
                if (isEmpty(fieldsPayload)) {
                    const newsLength = mySearchedNews.length + moreSearchedNews.length;
                    // const paginatedPayload = {
                    //     ...payload,
                    //     from: newsLength,
                    // };

                    searchProxy.from = newsLength;
                    searchProxy.filterForLast7Days = filterForLast7Days;

                    searchNewsByTags(neededFilterSet, {
                        ...searchProxy,
                        primarySourcesOnly: filterSet.showPrimarySourcesOnly,
                        time: timeRange,
                        from: newsLength,
                    }).then((res) => {
                        const newResults = res.results;
                        newResults?.forEach((news) => {
                            const newNews = {
                                ...news,
                                read: uniq(myReadList).includes(news.SK),
                                publishedAt: new Date(news.NK).toISOString(),
                                slug: news.SK,
                                bookmarked: uniq(allBookmarks.data).includes(news.SK),
                            };
                            return pushNewSearchedNews(newNews);
                        });
                    });
                } else {
                    const newsLength = filteredNews.length;
                    const paginatedPayload = {
                        ...fieldsPayload,
                        from: newsLength,
                    };

                    searchTagsAcrossFields({
                        ...paginatedPayload,
                        filter: encodedUrl,
                        filterForLast7Days,
                    }).then((res) => {
                        const newResults = res.response.filter((item) => item.iconType !== "Press Release");

                        newResults.forEach((news) => {
                            const newNews = {
                                ...news,
                                read: uniq(myReadList).includes(news.SK),
                                publishedAt: new Date(news.NK).toISOString(),
                                slug: news.SK,
                                bookmarked: uniq(allBookmarks.data).includes(news.SK),
                            };
                            return pushNewSearchedNews(newNews);
                        });
                    });
                }
            }
        }
    };

    const allFilterSets = concat(filterSets, defaultFilterSets) as FilterSet[];

    // Add type for value
    const value: NewsFeedContextValue = {
        display,
        setDisplay,
        filterSet,
        setFilterSet,
        allFilterSets,
        filterSets,
        setFilterSets,
        openFilterModal,
        setOpenFilterModal,
        showScrollToTopButton,
        setShowScrollToTopButton,
        scrollToTop,
        setScrollToTop,
        newsItem,
        setNewsItem,
        newsIndex,
        setNewsIndex,
        copyValue,
        copy,
        showAlert,
        setShowAlert,
        alertMessage,
        setAlertMessage,
        showShare,
        setShowShare,
        showSearchNewslist,
        setShowSearchNewslist,
        // setPayload,
        fieldsPayload,
        setFieldsPayload,
        searchQuery,
        setSearchQuery,
        // fieldsSearchQuery,
        searchQueryCategory,
        setSearchQueryCategory,
        setSearchNewsList,
        loading,
        setLoading,
        newsLoading,
        setNewsLoading,
        setNewSearchedNews,
        hideResults,
        setHideResults,
        renderedNews,
        setRenderedNews,
        loadMoreItems,
        renderedResults,
        neededFilterSet,
        timeRange,
        setTimeRange,
        newsLengthFromSource,
        setNewsLengthFromSource,
        filterSetChanged,
        setFilterSetChanged,
        showResultsBanner,
        setShowResultsBanner,
        showUpgradeModal,
        setShowUpgradeModal,
    };

    return <NewsFeedContext.Provider value={value}>{children}</NewsFeedContext.Provider>;
};

// Update useNewsFeedContext hook
const useNewsFeedContext = (): NewsFeedContextValue => useContext(NewsFeedContext);

export { useNewsFeedContext, NewsFeedContextProvider };
