import { useState } from "react";
import useMarketStats from "@app/dashboard/useMarketStats";
import { useSpring, animated } from "@react-spring/web";
import millify from "millify";
import { SentimentBadge, SmallDailyChangeBadge } from "@/components/badge/statistics";
import Tooltip from "@/components/Tooltip";

const GlobalMarketStats = ({ globalMetrics }) => {
    const [marketStats] = useMarketStats(globalMetrics);
    const bitcoinDominance = useSpring(marketStats.bitcoinDominance);
    const dailySentiment = useSpring(marketStats.dailySentiment);
    const totalMarketCap = useSpring(marketStats.totalMarketCap);
    const ethereumGas = useSpring(marketStats.ethereumGas);
    const fearAndGreed = useSpring(marketStats.fearAndGreed);
    const bitcoinHalving = useSpring(marketStats.bitcoinHalving);

    // on click toggles between daily sentiment score and emoji
    const [toggle, setToggle] = useState(true);
    const toggleSentiment = () => setToggle(!toggle);
    const timeNow = new Date();
    const hour = timeNow.getUTCHours();
    const minute = timeNow.getUTCMinutes();

    return (
        <div className="flex flex-col">
            <div className="flex items-center border-b-1 border-[#252932] px-2 py-[14px]">
                <p className="text-sm font-normal leading-4 text-white">Market Stats</p>
            </div>
            <div className="flex flex-col gap-2 p-2">
                <div className="flex w-full justify-between gap-2">
                    <div className="flex w-[136px] flex-col gap-1 pr-[6px]">
                        <p className="text-xs font-light text-[#7A869B]">Total Market Cap</p>
                        <div className="flex gap-1">
                            <div className="flex gap-[2px] font-normal text-white">
                                <animated.h1 className="text-lg leading-6">
                                    {totalMarketCap.current.to((x) =>
                                        millify(x, { precision: 3, space: false }),
                                    )}
                                </animated.h1>
                                <p className="text-xs">$</p>
                            </div>
                            <SmallDailyChangeBadge value={marketStats.totalMarketCap.change.toFixed(2)} />
                        </div>
                    </div>
                    <div className="flex w-[136px] flex-col gap-1 pr-[6px]">
                        <p className="text-xs font-light text-[#7A869B]">Bitcoin Dominance</p>
                        <div className="flex gap-1">
                            <div className="flex gap-[2px] font-normal text-white">
                                <animated.h1 className="text-lg leading-6">
                                    {bitcoinDominance.current.to((x) => x.toFixed(2))}
                                </animated.h1>
                                <h1 className="text-xs leading-6">%</h1>
                            </div>
                            <SmallDailyChangeBadge value={marketStats.bitcoinDominance.change.toFixed(2)} />
                        </div>
                    </div>
                    <div className="daily-sentiment relative flex w-[136px] flex-col gap-1">
                        <p className="text-xs font-light text-[#7A869B]">Today's Forecast</p>
                        <Tooltip
                            className="tooltip-left flex cursor-pointer items-center gap-1 text-xs font-light text-white"
                            content={`${
                                dailySentiment.length.animation.to
                            } articles from open of business: 12AM UTC till now: ${
                                hour < 10 ? `0${hour}` : hour
                            }:${minute < 10 ? `0${minute}` : minute} UTC`}
                        >
                            <div className="flex cursor-pointer items-center gap-1">
                                {/* toggle between score and emoji */}
                                <div
                                    className="flex gap-[2px] font-normal text-white"
                                    onClick={toggleSentiment}
                                >
                                    {/* {toggle ? (
                                    <div className="flex cursor-default select-none gap-[1px] whitespace-nowrap">
                                        <animated.h1 className="text-lg leading-6">
                                            {Number.isNaN(dailySentiment.score.animation.to)
                                                ? 0
                                                : dailySentiment.score.to((x) => x.toFixed(0))}
                                        </animated.h1>
                                        <div className="text-xs leading-6">%</div>
                                    </div>
                                ) : ( */}
                                    <div className="cursor-default select-none">
                                        <animated.h1 className="text-lg leading-6">
                                            {dailySentiment.emoji.to((x) => x)}
                                        </animated.h1>
                                    </div>
                                    {/* )} */}
                                    <p className="text-xs">{marketStats.dailySentiment.highestSentiment}</p>
                                </div>
                                <SentimentBadge value={marketStats.dailySentiment.highestSentiment} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className="flex w-full justify-between gap-2">
                    <div className="flex w-[136px] flex-col gap-1 pr-[6px]">
                        <p className="text-xs font-light text-[#7A869B]">Eth Gas</p>
                        <div className="flex gap-[2px] font-normal text-white">
                            <animated.h1 className="text-lg leading-6 text-white">
                                {ethereumGas.proposeGasPrice.to((x) => x.toFixed(0))}
                            </animated.h1>
                            <p className="text-xs">Gwei</p>
                        </div>
                    </div>
                    <div className="flex w-[136px] flex-col gap-1 pr-[6px]">
                        <p className="text-xs font-light text-[#7A869B]">Fear & Greed</p>
                        <div className="flex gap-[2px] font-normal text-white">
                            <animated.h1 className="text-lg leading-6">
                                {fearAndGreed.today.to((x) => Number(x).toFixed(0))}
                            </animated.h1>
                            <p className="text-xs">/100</p>
                        </div>
                    </div>
                    <div className="flex w-[136px] flex-col gap-1">
                        <p className="text-xs font-light text-[#7A869B]">Bitcoin Halving</p>
                        <div className="flex gap-[2px] font-normal text-white">
                            <animated.h1 className="text-lg leading-6">
                                {bitcoinHalving.current.to((x) => x.toFixed(0))}
                            </animated.h1>
                            <p className="text-xs">days estimate</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalMarketStats;
