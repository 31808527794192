import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CUSTOMIZE } from "@app/dashboard/news/filterType";
import Link from "next/link";
import AssetIcon from "@/components/asset/AssetIcon";
import { Dispatch, SetStateAction } from "react";

// AssetItem component renders a single asset item with a link to its details page
function AssetItem({ asset }) {
    const localAsset = asset.data;
    return (
        <Link
            href={{
                pathname: `/assets/${localAsset?.["Source URL"]}`,
            }}
        >
            <div className="flex w-full items-center gap-1 px-0 py-[2px]">
                <AssetIcon className="h-4 w-4" acronym={localAsset?.["Ticker"]} />
                <p className="text-xs font-normal text-white">{localAsset?.["Coin Name"]}</p>
                <p className="text-xs font-light uppercase text-[#7A869B]">{localAsset?.["Ticker"]}</p>
            </div>
        </Link>
    );
}

// AllCoins component renders a list of all coins and allows navigation back to the landing page
export default function AllCoins({
    setPage,
    assets,
}: {
    setPage: Dispatch<SetStateAction<CUSTOMIZE>>;
    assets;
}) {
    return (
        <div className="flex h-screen w-full flex-col">
            <div className="flex w-full flex-col items-start border-b-1 border-[#2F343F] px-4 py-3">
                <div className="flex items-center gap-2">
                    <ArrowLeftIcon
                        className="h-4 w-4 cursor-pointer text-[#7A869B] hover:brightness-75"
                        onClick={() => setPage(CUSTOMIZE.LANDING_PAGE)}
                    />
                    <h1 className="text-base font-normal leading-5 text-white">All Coins</h1>
                </div>
            </div>
            <div className="scrollbar-hide flex h-screen flex-col items-start overflow-auto bg-[#20242C] px-4 py-0">
                <div className="flex w-full flex-col items-start gap-3 px-0 py-3">
                    <p className="text-xs font-light text-[#A1ADC1]">List of all coins</p>
                    <div className="flex w-full flex-col items-start gap-2 p-0">
                        {assets.map((asset, i) => (
                            <AssetItem asset={asset} key={i} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
