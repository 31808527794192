import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { InView } from "react-intersection-observer";
import MemoLoading from "@assets/SVG/Loading";
import { SearchType } from "@app/dashboard/news-feed/searchType";
import { Dispatch, SetStateAction, useState } from "react";
import TagResponse from "@app/dashboard/news-feed/Search/TagResponse";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";
import { getArticleSource } from "@/types/util";
import { uniqBy } from "lodash";

export default function TagSearchResult(props: {
    assetsResults: any[];
    companiesResults: any[];
    sourcesResults: any[];
    keyword: string;
    loading: boolean;
    othersResults: any[];
    peopleResults: any[];
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    tagSearchResponse: any[];
    typeOfSearch: SearchType;
    setKeywordSearch: () => void;
    setShow: Dispatch<SetStateAction<boolean>>;
    addSearchItem: (tag: string, category: string) => void;
}) {
    // this is for assets search, when we have a case where the display name of a source is "Bitcoinist.com" and another is "Bitcoinist".
    // since they are the same, one needs be removed
    const filteredSources = props.sourcesResults.map((item) => {
        const source = getArticleSource(item._source.display);
        return {
            ...item,
            _source: {
                ...item._source,
                display: source,
                tag: source,
            },
        };
    });

    const uniqueCompanies = uniqBy(props.companiesResults, (item) => item._source.display.toLowerCase());
    const uniqueSources = uniqBy(filteredSources, (item) => item._source.display.toLowerCase());

    const asset_count: number = props.tagSearchResponse?.filter((item: any) => item.key === "ASSET")?.[0]
        ?.doc_count;
    const source_count: number = props.tagSearchResponse?.filter((item: any) => item.key === "SOURCE")?.[0]
        ?.doc_count;
    const companies_count: number = props.tagSearchResponse?.filter((item: any) => item.key === "ORG")?.[0]
        ?.doc_count;
    const people_count: number = props.tagSearchResponse?.filter((item: any) => item.key === "PERSON")?.[0]
        ?.doc_count;

    const tabs = [
        { name: "Coins", count: asset_count || 0 },
        { name: "Sources", count: uniqueSources.length < 10 ? uniqueSources.length : source_count || 0 },
        { name: "People", count: people_count || 0 },
        {
            name: "Companies",
            count: uniqueCompanies.length < 10 ? uniqueCompanies.length : companies_count || 0,
        },
    ]; //, "Topics"];

    const [tab, setTab] = useState(tabs[0]);

    const subscriptionSnapshot = useSnapshot(userPaymentState);
    const userIsPro = subscriptionSnapshot.subIsActive;

    return (
        <div
            className="absolute -left-[8px] top-[2.3rem] z-20 flex h-[370px] w-[calc(100vw-18px)] flex-col overflow-x-hidden overflow-y-scroll scroll-smooth rounded-lg border-1 border-[#3D4552] bg-[#20242C] px-0 py-[5px] shadow-lg scrollbar scrollbar-track-[#252932] scrollbar-thumb-[#306CE8] scrollbar-track-rounded-lg scrollbar-w-1 lg:w-[calc((100vw-27px)/2)] 1xl:w-[calc((100vw-37px)/3)]"
            // onPointerLeave={() => props.setHideResults(true)}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="w-full p-2">
                <button
                    className="btn h-[fit-content] min-h-0 rounded-lg border-none bg-[#306CE8] px-[10px] py-2 normal-case" // onClick={handleAddAssetToWatchlist}
                    onClick={() => {
                        // if (!userIsPro) {
                        //     props.setShow(true);
                        // } else {
                        props.setKeywordSearch();
                        // }
                    }}
                >
                    <p className="text-xs font-normal text-white">Search Keywords Only</p>
                </button>
            </div>
            {props.loading ? (
                <div className="my-auto flex justify-center">
                    <MemoLoading className="h-4 w-4" />
                </div>
            ) : props.tagSearchResponse?.length > 0 ? (
                <>
                    <nav className="scrollbar-hide mb-2 flex w-full shrink-0 items-center gap-3 overflow-auto border-b-1 border-[#2F343F] px-2 text-xs font-normal text-[#7A869B]">
                        {tabs.map((item, i: number) => {
                            return (
                                <div
                                    className={`flex max-w-[13rem] cursor-pointer items-center gap-[6px] whitespace-nowrap border-[#306CE8] px-1 py-[14px] font-light hover:brightness-75 ${
                                        tab.name === item.name && `border-b-2 font-normal text-white`
                                    }`}
                                    key={i}
                                    onClick={() => setTab(item)}
                                >
                                    <p>
                                        {item?.name.length > 16
                                            ? item.name.substring(0, 16) + "..."
                                            : item.name}
                                    </p>
                                    <div>{item.count}</div>
                                </div>
                            );
                        })}
                    </nav>
                    <div className="flex flex-col items-start overflow-auto bg-[#20242C] px-2 py-0">
                        <TagResponse
                            tab={tab}
                            assetsResults={props.assetsResults}
                            companiesResults={uniqueCompanies}
                            peopleResults={props.peopleResults}
                            sourcesResults={uniqueSources}
                            keyword={props.keyword}
                            addNewItem={props.addSearchItem}
                        />

                        {/* @ts-ignore */}
                        <InView
                            as="div"
                            className="w-full"
                            onChange={(inView) => {
                                props.setIsVisible(inView);
                            }}
                        >
                            <div className="my-2 flex justify-center" />
                        </InView>
                    </div>
                </>
            ) : (
                <div className="my-auto flex flex-col items-center justify-center gap-2 text-[#505A68]">
                    <ExclamationCircleIcon className="h-8 w-8" />
                    <p className="text-[#505A68]">No match found</p>
                </div>
            )}
        </div>
    );
}
