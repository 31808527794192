"use client";

import { STATUS } from "@/types/api";
import { useContext, useEffect, useState } from "react";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import { useSelectedLayoutSegment } from "next/navigation";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";

export default function Timestamp() {
    const article = useContext(NewsItemContext);
    const segment = useSelectedLayoutSegment();

    const userSubscriptionStatus = useSnapshot(userPaymentState);
    const { subIsActive } = userSubscriptionStatus;
    const userIsPro = subIsActive;

    const publishedAt = new Date(article.publishedAt);
    const dateIsYesterday = isYesterday(publishedAt);
    const dateIsToday = isToday(publishedAt);
    const selected = article.slug === decodeURIComponent(segment!);
    const isRecent = article.new;

    // Convert the addedAt timestamp to a Date object
    const addedAt = new Date(article?.addedAt || article.publishedAt);

    const [elapsedTime, setElapsedTime] = useState(
        Math.floor((new Date().getTime() - addedAt.getTime()) / 1000),
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Calculate the elapsed hours, minutes, and seconds
    const hours = Math.floor(elapsedTime / 3600);
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = elapsedTime % 60;

    const { display } = useNewsFeedContext();
    return (
        <div
            className={`${
                selected
                    ? "text-white"
                    : article.read
                    ? "text-[#505A68]"
                    : article.status === STATUS.IMPORTANT
                    ? "text-[#C83D4D]"
                    : "text-[#7A869B]"
            } flex w-12 flex-col gap-[2px] group-hover:text-white`}
        >
            {isRecent && userIsPro ? (
                <p className="whitespace-nowrap text-left text-[10px] font-light leading-3">
                    {hours > 0
                        ? hours === 1
                            ? `1 hour`
                            : `${hours} hours`
                        : minutes < 1
                        ? `${seconds} sec`
                        : `${minutes} m`}
                </p>
            ) : !dateIsToday ? (
                <p className="text-left text-[10px] font-light leading-3">
                    {dateIsYesterday
                        ? `Yesterday ${format(publishedAt, "h:mma")}`
                        : format(publishedAt, "MMM do h:mma")}
                </p>
            ) : dateIsToday && display === "thin" ? (
                <p className="w-12 text-left text-[10px] font-light leading-3">{format(publishedAt, "p")}</p>
            ) : (
                display !== "thin" && (
                    <p className="w-12 text-left text-[10px] font-light leading-3">
                        {format(publishedAt, "p")}
                    </p>
                )
            )}
        </div>
    );
}
