// bookmarkedListState.js
import { proxy } from "valtio";

const watchlistState = proxy({
    data: [] as any[],
    isLoading: false,
    error: null,
});

export default watchlistState;
