import Alert from "@/components/Alert";
import { searchProxy } from "@/store/searchStore";
import { ShareModal } from "@app/dashboard/[articleId]/news-detail/ShareModal";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import InfiniteNewsList from "@app/dashboard/news-feed/news-list/InfiniteNewsList";
import MemoTiingo from "@assets/SVG/Tiingo";
import { CheckBadgeIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { isEmpty, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import userPaymentState from "@/store/userPaymentStore";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
import MemoLoading from "@assets/SVG/Loading";
import youtubeVideoProxy from "@/store/youtube";
import YoutubeVideoModal from "@app/dashboard/[articleId]/news-detail/YoutubeVideoModal";
import MemoCryptonews from "@assets/SVG/Cryptonews";
import MemoGavel from "@assets/SVG/Gavel";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoNews from "@assets/SVG/News";
import MemoPodcast from "@assets/SVG/Podcast";
import MemoReddit from "@assets/SVG/Reddit";
import MemoSpeaker from "@assets/SVG/Speaker";
import MemoX from "@assets/SVG/X";
import MemoYouTube from "@assets/SVG/YouTube";
import {
    BoltIcon,
    // CheckBadgeIcon,
    CurrencyDollarIcon,
    MinusCircleIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";
import Tooltip from "@/components/Tooltip";
import filterSetList from "@/store/filterSetStore";
import { useStytchSession } from "@stytch/nextjs";
import { UpgradeSection } from "@/types/util";

function Icon({ sourceKey }: { sourceKey: string }) {
    let icon = null as any;
    let label = "";

    const [pointer, setPointer] = useState(false);

    switch (sourceKey) {
        case "showBlockbeatNews":
            icon = (
                <div className="flex h-4 w-4 items-center justify-center rounded-full bg-[#306CE8]">
                    <MemoLogo className="h-[10px] w-2" color="#FFFFFF" />
                </div>
            );
            label = "BlockBeat";
            break;
        case "showTwitterNews":
            icon = <MemoX className="h-4 w-4 rounded-full bg-black" />;
            label = "Twitter";
            break;
        case "showRedditNews":
            icon = <MemoReddit className="h-4 w-4" />;
            label = "Reddit";
            break;
        case "showNewsNews":
            icon = <MemoNews className="h-4 w-4" />;
            label = "News";
            break;
        case "showPressRelease":
            icon = <MemoSpeaker className="h-4 w-4" />;
            label = "Press Release";
            break;
        case "showGovernmentPress":
            icon = <MemoGavel className="h-4 w-4" />;
            label = "Government Press";
            break;
        case "showBlogNews":
            icon = <MemoCryptonews className="h-4 w-4" />;
            label = "Blog";
            break;
        case "showYouTubeNews":
            icon = <MemoYouTube className="h-4 w-4" />;
            label = "YouTube";
            break;
        case "showPodcastNews":
            icon = (
                <div className="w-full rounded-full bg-[#306CE8]">
                    <MemoPodcast className="h-4 w-4" />
                </div>
            );
            label = "Podcast";
            break;
        case "hideAssetNews":
            icon = <CurrencyDollarIcon className="h-4 w-4" />;
            label = "Asset";
            break;
        case "lowQualityNews":
            icon = <BoltIcon className="h-4 w-4" />;
            label = "Low Quality";
            break;
        case "showPrimarySourcesOnly":
            icon = <CheckBadgeIcon className="h-4 w-4 text-[#2455B7]" />;
            label = "Primary Sources Only";
            break;
    }

    const { filterSets, setFilterSets, filterSet, setFilterSet } = useNewsFeedContext();
    if (!icon) return <></>;

    return (
        <Tooltip
            className="tooltip-custom-warning tooltip-bottom z-10 flex cursor-pointer items-center justify-center text-xs font-light"
            content={label}
        >
            <div
                onPointerEnter={() => setPointer(true)}
                onPointerLeave={() => setPointer(false)}
                className="flex h-4 w-4 items-center justify-center rounded-full"
            >
                {pointer ? (
                    <XCircleIcon
                        onClick={() => {
                            filterSet[sourceKey] = !filterSet[sourceKey];
                            if (sourceKey === "showPrimarySourcesOnly") {
                                if (!filterSet[sourceKey]) searchProxy.primarySourcesOnly = true;
                                else searchProxy.primarySourcesOnly = false;
                            }
                            if (filterSet.filterName !== "Saved") {
                                // this is useful so we can use use source toggle buttons for saved news also
                                setFilterSets(uniqBy([...filterSets, filterSet], "filterIndex"));
                                setFilterSet(filterSet);
                            } else {
                                // this is useful so we can use use source toggle buttons for saved news also
                                // HACK: also, since the customized filterset is what is being used for this, it is simply returned so the state is updated.
                                setFilterSets(uniqBy([...filterSets], "filterIndex"));
                            }
                        }}
                    />
                ) : (
                    icon
                )}
            </div>
        </Tooltip>
    );
}

const NewsList = () => {
    const {
        newsItem,
        copyValue,
        newsLoading,
        filterSet,
        filterSets,
        setFilterSet,
        setFilterSets,
        copy,
        showAlert,
        setShowAlert,
        alertMessage,
        setAlertMessage,
        showShare,
        setShowShare,
        showSearchNewslist,
        renderedResults,
        newsLengthFromSource,
        filterSetChanged,
        showResultsBanner,
        setShowResultsBanner,
        showUpgradeModal,
        setShowUpgradeModal,
    } = useNewsFeedContext();

    const searchSnapshot = useSnapshot(searchProxy);
    const { session } = useStytchSession();
    const youtubeSnapshot = useSnapshot(youtubeVideoProxy);
    const subscriptionSnapshot = useSnapshot(userPaymentState);
    const userIsPro = subscriptionSnapshot.subIsActive;
    const userPaymentIsLoading = subscriptionSnapshot.loading;
    // I needed to use a different state for either of the upgradeModal states.
    // This was because both modals were rendered at the same time on one of them being toggled.
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [showYoutubeWidget, setShowYoutubeWidget] = useState(false);
    const { data: fil } = useSnapshot(filterSetList);
    const [anyDifference, setAnyDifference] = useState(false);

    useEffect(() => {
        if (youtubeSnapshot.show) {
            if (!showYoutubeWidget) setShowYoutubeWidget(true);
        } else {
            if (showYoutubeWidget) setShowYoutubeWidget(false);
        }
    }, [youtubeSnapshot.show]);

    useEffect(() => {
        if (!session) setShowResultsBanner(false);
        // this is a dummy to clean an overlay issue when the auth modal shows up
    }, [session]);

    const falseAttributes = Object.entries(filterSet)
        .filter(([key, value]) => typeof value === "boolean" && value === false)
        .map(([key, value]) => key)
        .filter(
            (item) =>
                item !== "hideAssetNews" && item !== "lowQualityNews" && item !== "showPrimarySourcesOnly",
        );

    useEffect(() => {
        const filInitial = fil.find((f) => f.filterIndex === filterSet.filterIndex);

        if (showSearchNewslist) {
            if (!showResultsBanner) setShowResultsBanner(true);
        }
        // else {
        //     if (showResultsBanner) setShowResultsBanner(false);
        // }

        if (filInitial && filterSet.filterName !== "All News") {
            let anyDiff = false;
            setAnyDifference(false);
            // I am using both anyDifference and anyDiff for this because I don't want to have to watch for anydifference

            Object.keys(filInitial).forEach((key) => {
                if (key in filterSet && filInitial[key] !== filterSet[key]) {
                    anyDiff = true;
                    setAnyDifference(true);
                }
            });

            if (anyDiff && !showResultsBanner) {
                setShowResultsBanner(true);
            } else if (!anyDiff && showResultsBanner) {
                setShowResultsBanner(false);
            }
        }
    }, [filterSetChanged, showSearchNewslist, filterSet.filterName]);

    return (
        <div className="flex h-full w-full flex-col overflow-hidden">
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            {showYoutubeWidget && (
                <YoutubeVideoModal
                    url={youtubeSnapshot.video_url}
                    show={showYoutubeWidget}
                    setShow={setShowYoutubeWidget}
                />
            )}
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Close_Ad}
            >
                <></>
            </UpgradeModal>
            {showShare && (
                <ShareModal
                    news={newsItem}
                    show={showShare}
                    setShow={setShowShare}
                    value={copyValue}
                    copy={copy}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            )}
            {showAlert && (
                <Alert show={showAlert} setShow={setShowAlert} content={alertMessage ?? "Headline Copied"} />
            )}
            {showResultsBanner ? (
                <div className="flex w-[calc(100%-2px)] flex-col">
                    <div className="flex w-full place-content-between items-center gap-[10px] border-b-1 border-[#252932] bg-[#20242C] p-3 text-[10px] font-light leading-3 text-[#7A869B]">
                        <div>
                            {filterSet.filterName !== "All News" && (
                                // this is so we don't set the filterset as the "Customized" one, because it is not meant to be seen by the user so they don't feel one has been automatically created for them
                                <div className="flex items-center gap-2">
                                    {filterSet.filterName !== "Customized" && !anyDifference && (
                                        <div className="flex items-center gap-2">
                                            <span className="truncate">
                                                filterSet: {filterSet.filterName}&nbsp;
                                            </span>
                                            <div className="h-4 w-[1px] bg-[#7A869B]" />
                                        </div>
                                    )}

                                    {(!isEmpty(falseAttributes) || filterSet.showPrimarySourcesOnly) && (
                                        <div className="flex items-center gap-1">
                                            {!isEmpty(falseAttributes) && (
                                                <div className="flex items-center gap-1">
                                                    <p className="text-nowrap">Hidden sources:</p>
                                                    <div className="flex items-center gap-1">
                                                        {falseAttributes.map((item) => (
                                                            <Icon key={item} sourceKey={item} />
                                                        ))}
                                                    </div>
                                                    <div className="mx-2 h-4 w-[1px] shrink-0 bg-[#7A869B]" />
                                                </div>
                                            )}
                                            {filterSet.showPrimarySourcesOnly && (
                                                <p className="text-nowrap">Primary Sources Only</p>
                                            )}
                                            <div className="mx-2 h-4 w-[1px] shrink-0 bg-[#7A869B]" />
                                            <Tooltip
                                                className="tooltip-custom-warning tooltip-bottom z-10 flex cursor-pointer items-center justify-center text-xs font-light"
                                                content="Clear all Filters"
                                            >
                                                <MinusCircleIcon
                                                    onClick={() => {
                                                        falseAttributes.forEach(
                                                            (key) => (filterSet[key] = true),
                                                        );
                                                        filterSet["showPrimarySourcesOnly"] = false;
                                                        setFilterSets(
                                                            uniqBy([...filterSets, filterSet], "filterIndex"),
                                                        );
                                                        setFilterSet(filterSet);
                                                        setShowResultsBanner(false);
                                                    }}
                                                    className="h-4 w-4 cursor-pointer hover:brightness-75"
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {newsLoading ? (
                            <div className="">
                                <MemoLoading className="h-3 w-3" />
                            </div>
                        ) : showSearchNewslist ? (
                            <div className="shrink-0">
                                results:{" "}
                                {renderedResults.length > searchSnapshot.total
                                    ? renderedResults.length
                                    : searchSnapshot.total === 10000
                                    ? "9999+"
                                    : searchSnapshot.total}
                            </div>
                        ) : (
                            <div className="shrink-0">
                                results:{" "}
                                {newsLengthFromSource === 10000 ? "9999+" : newsLengthFromSource || 0}
                            </div>
                        )}
                    </div>
                    {showSearchNewslist && !userIsPro && (
                        <div className="flex h-9 w-[calc(100%-2px)] place-content-between items-center gap-[10px] border-b-1 border-[#252932] bg-[#C83D4D] px-4 py-1">
                            <p className="w-auto text-xs font-normal text-white">
                                Search History Is Limited to 2 Weeks -{" "}
                                <span
                                    className="cursor-pointer hover:brightness-75"
                                    onClick={() => setShowUpgradeModal(true)}
                                >
                                    Upgrade Now
                                </span>
                            </p>
                            <XMarkIcon
                                onClick={() => setShowUpgradeModal(true)}
                                className="h-[16.25px] w-[16.25px] cursor-pointer text-white hover:scale-125 hover:brightness-75"
                                strokeWidth={2.5}
                            />
                        </div>
                    )}
                </div>
            ) : (
                !userPaymentIsLoading &&
                !userIsPro && (
                    <div className="static flex w-[calc(100%-0.5px)] flex-col border-b-1 border-[#252932]">
                        <div className="flex w-full gap-2 bg-[#1C1F27] px-2 pb-2 pt-2">
                            <div className="flex w-12 flex-col gap-[2px] text-[#7A869B]">
                                <p className="whitespace-nowrap text-left text-[10px] font-light leading-3">
                                    Ad
                                </p>
                            </div>
                            <div className="flex w-[calc(100%-3.5rem)] flex-col gap-1">
                                <div className="flex justify-between gap-1 align-top">
                                    <p className="text-sm font-normal leading-4 text-white">
                                        A Reliable, Enterprise-Grade Financial Markets API Tiingo’s APIs power
                                        hedge funds, tech companies, and individuals.
                                    </p>
                                    <XMarkIcon
                                        onClick={() => setShowUpgradeModal(true)}
                                        className="h-8 w-8 cursor-pointer text-[#7A869B] hover:scale-125 hover:brightness-75"
                                        strokeWidth={2.5}
                                    />
                                </div>
                                <div className="flex gap-1">
                                    <MemoTiingo className="h-4 w-[69px]" />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}

            {isEmpty(renderedResults) && (
                <div className="flex h-full w-full flex-col flex-wrap gap-2 pt-8 align-middle">
                    <ExclamationCircleIcon className="h-8 w-8 self-center" />
                    {showSearchNewslist ? (
                        <p className="self-center text-[#505A68]">No news items matches your search</p>
                    ) : (
                        <p className="self-center text-[#505A68]">No news items to display</p>
                    )}
                </div>
            )}

            {newsLoading ? (
                <div className="mt-8 flex justify-center">
                    <MemoLoading className="h-4 w-4" />
                </div>
            ) : (
                <InfiniteNewsList />
            )}
        </div>
    );
};

export default NewsList;
