import { SVGProps, memo } from "react";

function Speaker(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} rx={8} fill="#7219F7" />
            <path
                d="M10.034 4.81a9.63 9.63 0 01-3.597.69h-.312a2.188 2.188 0 00-.367 4.344c.14.57.333 1.12.574 1.643a.762.762 0 001.076.331l.274-.158a.804.804 0 00.322-1.028 7.125 7.125 0 01-.262-.67 9.62 9.62 0 012.292.603 9.052 9.052 0 00.466-2.877 9.052 9.052 0 00-.466-2.877zM10.609 4.559c.334.981.516 2.034.516 3.129a9.675 9.675 0 01-.587 3.33.312.312 0 10.586.214 10.319 10.319 0 00.59-2.679 1.247 1.247 0 000-1.731 10.275 10.275 0 00-.59-2.68.313.313 0 00-.586.215c.024.067.048.134.07.202z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoSpeaker = memo(Speaker);
export default MemoSpeaker;
