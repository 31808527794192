import { useState } from "react";
import SourceIcon from "@/components/source/SourceIcon";
import MemoFlame from "@assets/SVG/Flame";
import { useSelectedLayoutSegment } from "next/navigation";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
// import MemoSentimentGraph from "@assets/SVG/Sentiment/SentimentGraph";
import { compact, isEmpty, maxBy, take, uniq, uniqBy } from "lodash";
import { DISPLAY, News } from "@/types/api";
import Vote from "@/components/vote/Vote";
import { SymbolLabel } from "@app/TSWidgets/components/SymbolLabel";
import {
    extractStringsFromArray,
    getArticleSource,
    transformStringsToArrayOfObjects,
    unwantedAssets,
} from "@/types/util";
import NewItemTag from "@app/dashboard/news-feed/news-list/NewsItemTag";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { searchProxy } from "@/store/searchStore";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import MemoLogo from "@assets/SVG/Logo/logo";

export default function NewsListItemBody({ article }: { article: News }) {
    // const article = useContext(NewsItemContext);
    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);
    const [popupVisible, setPopupVisible] = useState(false);
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);

    const { display, searchQuery, setSearchQuery, searchQueryCategory, setSearchQueryCategory } =
        useNewsFeedContext();

    const compactedAssets = compact([...article.assets.slice(1), article.assets[0]]);
    const filteredAssets = compactedAssets.filter((item) => !unwantedAssets.includes(item.name));
    const mentionedAssets: { name: string; symbol: string }[] = !isEmpty(filteredAssets)
        ? filteredAssets
        : [];

    const extractedStrings = uniq(extractStringsFromArray(article?.tagsData || article?.tags));

    const transformedTags: {
        type: string;
        data: string;
    }[] = compact(transformStringsToArrayOfObjects(extractedStrings));

    const tagsWithType = article.tagsData?.filter((tag) => tag.type);
    let renderedTags = transformedTags;
    if (tagsWithType && !isEmpty(tagsWithType))
        renderedTags = uniqBy([...transformedTags, ...tagsWithType], (tag) => tag.data.toLowerCase());

    const newsSource = getArticleSource(article.source);

    const { negativeSentiment, neutralSentiment, positiveSentiment, data } = article;

    let sentimentIcon;
    let sentimentData;

    if (negativeSentiment && neutralSentiment && positiveSentiment) {
        const highestSentiment = Math.max(negativeSentiment, neutralSentiment, positiveSentiment);

        sentimentData = {
            positive: positiveSentiment,
            neutral: neutralSentiment,
            negative: negativeSentiment,
        };

        if (highestSentiment === positiveSentiment) {
            sentimentIcon = "Positive";
        } else if (highestSentiment === negativeSentiment) {
            sentimentIcon = "Negative";
        } else {
            sentimentIcon = "Neutral";
        }
    } else if (data?.sentiment) {
        const { sentiment } = data;

        sentimentData = sentiment;

        if (!isEmpty(sentiment)) {
            const highestSentiment = maxBy(Object.keys(sentiment), (s) => sentiment[s]);

            if (highestSentiment === "positive") {
                sentimentIcon = "Positive";
            } else if (highestSentiment === "negative") {
                sentimentIcon = "Negative";
            } else {
                sentimentIcon = "Neutral";
            }
        }
    }

    // const [showSentimentTooltip, setShowSentimentTooltip] = useState(false);

    const addNewItem = (tag: string, category: string) => {
        // if (!userIsPro) {
        //     // props.setShow(true);
        // } else {
        if (searchProxy[category] && !searchProxy[category]?.includes(tag)) {
            searchProxy[category].push(tag);
        }
        if (category === "source") {
            setSearchQueryCategory(["source"]);
            setSearchQuery([tag]);
            // setInput("");
            return;
        } else if (searchQuery.length < 2) {
            const allCategories = [...searchQueryCategory];
            const newCategories = [...allCategories, category];
            setSearchQueryCategory([...newCategories]);

            const allItems = [...searchQuery];
            const isAlreadyInList = allItems.some((i) => i === tag);

            if (!isAlreadyInList) {
                const newList = [...allItems, tag];

                setSearchQuery([...newList]);
                // return setInput("");
            }
        }
        // }
    };

    return (
        <>
            {display === DISPLAY.NORMAL && (
                <div className="relative flex items-center pr-4">
                    <div
                        className="flex cursor-pointer items-center gap-1 hover:brightness-75"
                        onClick={(e) => {
                            e.stopPropagation();
                            addNewItem(newsSource, "sources");
                        }}
                    >
                        {article?.iconType === "BlockBeat" && isSelected ? (
                            <MemoLogo
                                color="#FFF"
                                className={`${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                } flex h-[16px] w-[16px] items-center justify-center`}
                            />
                        ) : (
                            <SourceIcon
                                className={`${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                } ${
                                    article?.iconType === "Twitter" && "rounded-full bg-black"
                                } flex h-[16px] w-[16px] items-center justify-center`}
                                iconType={article?.iconType}
                            />
                        )}
                        <p
                            className={`${
                                isSelected ? "text-white" : article.read ? "text-[#505A68]" : "text-[#7A869B]"
                            }  max-w-[100px] cursor-pointer truncate whitespace-nowrap text-xs font-light hover:brightness-75`}
                        >
                            {article.iconType === "Twitter" ? `@${article.source}` : newsSource}
                        </p>
                    </div>

                    {article.iconType !== "Press Release" &&
                        (!isEmpty(mentionedAssets) || !isEmpty(renderedTags)) && (
                            <>
                                <div
                                    className={`mx-2 h-4 w-[1px] ${
                                        isSelected ? "bg-[#4170CD]" : "bg-[#2F343F]"
                                    }`}
                                >
                                    &nbsp;
                                </div>
                                <div className="flex h-full items-center overflow-x-auto">
                                    <div className="flex items-center gap-3">
                                        {mentionedAssets.length > 0 && (
                                            <div className="relative self-center">
                                                <div
                                                    className="z-0 m-0 flex cursor-pointer items-center gap-1 hover:brightness-75"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        addNewItem(mentionedAssets[0].symbol, "assets");
                                                    }}
                                                >
                                                    <CurrencyDollarIcon
                                                        className="h-4 w-4"
                                                        color={
                                                            article.read && !isSelected
                                                                ? "#505A68"
                                                                : "#D9CC5B"
                                                        }
                                                    />
                                                    <div
                                                        className={`flex cursor-pointer gap-0.5 whitespace-nowrap text-[10px] font-light leading-3 ${
                                                            article.read && !isSelected
                                                                ? "text-[#505A68]"
                                                                : "text-[#D9CC5B]"
                                                        }`}
                                                    >
                                                        <SymbolLabel
                                                            Symbol={mentionedAssets[0]?.symbol}
                                                            renderedName={mentionedAssets[0]?.name}
                                                        />
                                                        {mentionedAssets.length > 1 && (
                                                            <p>
                                                                {" "}
                                                                +
                                                                {mentionedAssets.length - 1 > 2
                                                                    ? 2
                                                                    : mentionedAssets.length - 1}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!isEmpty(renderedTags) && (
                                            <div className="flex gap-1.5 whitespace-nowrap">
                                                {take(renderedTags, 2)?.map((tag, i) => (
                                                    <NewItemTag
                                                        tag={tag}
                                                        isSelected={isSelected}
                                                        isRead={article.read}
                                                        key={i}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addNewItem(tag.data, "tags");
                                                        }}
                                                    />
                                                ))}
                                                {renderedTags.length > 2 && (
                                                    <div
                                                        className={`text-[10px] font-light leading-3 ${
                                                            isSelected
                                                                ? "text-[#A9C0F0]"
                                                                : article.read && !isSelected
                                                                ? "text-[#505A68]"
                                                                : "text-[#7A869B]"
                                                        }`}
                                                    >
                                                        +{renderedTags.length - 2}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    {article.ai?.hot && (
                        <div className="flex h-full items-center">
                            <MemoFlame
                                className={`h-4 w-4 ${
                                    article.read && !isSelected && "opacity-50 mix-blend-luminosity"
                                }`}
                            />
                        </div>
                    )}

                    <div className="flex items-center pr-2">
                        <div className={`mx-2 h-4 w-[1px] ${isSelected ? "bg-[#4170CD]" : "bg-[#2F343F]"}`}>
                            &nbsp;
                        </div>
                        <div className="flex items-center gap-2">
                            <div
                                className={`z-0 flex items-center gap-0 ${
                                    article.read && !isSelected && "opacity-60"
                                }`}
                            >
                                <Vote decorated={false} />
                            </div>
                        </div>
                    </div>

                    <div className="absolute right-0 flex items-center">
                        <div
                            className="relative z-0 m-0 flex w-4 cursor-pointer hover:brightness-75"
                            onPointerEnter={(e: any) => {
                                e.stopPropagation();
                                setPopupVisible(true);
                                setPosX(e.pageX);
                                setPosY(e.pageY);
                            }}
                            onPointerLeave={(e: any) => {
                                e.stopPropagation();
                                setPopupVisible(false);
                            }}
                        >
                            {popupVisible && !isEmpty(sentimentData) && (
                                <SentimentPopup sentimentData={sentimentData} X={posX} Y={posY} />
                            )}
                            {sentimentIcon === "Positive" && (
                                <MemoSmilingEmoji
                                    color={
                                        isSelected ? "#FFFFFF" : article.read && !isSelected ? "#505A68" : ""
                                    }
                                />
                            )}
                            {sentimentIcon === "Neutral" && (
                                <MemoNeutralEmoji
                                    color={
                                        isSelected ? "#FFFFFF" : article.read && !isSelected ? "#505A68" : ""
                                    }
                                />
                            )}
                            {sentimentIcon === "Negative" && (
                                <MemoFrowningEmoji
                                    color={
                                        isSelected ? "#FFFFFF" : article.read && !isSelected ? "#505A68" : ""
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
