import { SVGProps, memo } from "react";

function Link(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 14 15" fill="none" {...props}>
            <path
                d="M11.24 3.26a3.324 3.324 0 00-4.712 0l-.94.94-.947-.94.947-.947a4.672 4.672 0 016.6 0 4.672 4.672 0 010 6.6l-.947.947-.94-.947.94-.94a3.324 3.324 0 000-4.713zM9.829 5.613l-4.714 4.714-.94-.94 4.714-4.714.94.94zm-8.014.474l.947-.947.94.947-.94.94a3.324 3.324 0 000 4.713 3.324 3.324 0 004.713 0l.94-.94.947.94-.947.947a4.672 4.672 0 01-6.6 0 4.672 4.672 0 010-6.6z"
                fill="#8899A6"
            />
        </svg>
    );
}

const MemoLink = memo(Link);
export default MemoLink;
