import { useEffect, useState } from "react";
import { useList } from "react-use";
import { useEventsContext } from "@app/dashboard/EventsContext";
import { News } from "@/types/api";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";

const useNews = () => {
    const socket = useEventsContext();
    const [realTimeNews, { insertAt }] = useList([] as News[]);
    const [subIsActive, setSubIsActive] = useState(false);

    const subscriptionSnapshot = useSnapshot(userPaymentState);
    const userIsPro = subscriptionSnapshot.subIsActive;

    useEffect(() => {
        setSubIsActive(userIsPro);
    }, [userIsPro]);

    useEffect(() => {
        if (socket.connected) {
            socket.on(`news`, async function (msg) {
                // Add a 15-minute delay for basic (non-pro) users
                const subscriptionDelay = subIsActive ? 0 : 15 * 60 * 1000;
                // Add a random delay (0->30 for Twitter, 0->10 for the rest) to minimize bulk news updates at once
                const randomDelay =
                    msg.iconType === "Twitter"
                        ? Math.floor(Math.random() * 30 * 1000)
                        : Math.floor(Math.random() * 10 * 1000);
                const delay = subscriptionDelay + randomDelay;
                const timestampWithDelay = Date.now() + delay;

                // Add the delay before inserting the news
                setTimeout(() => {
                    insertAt(0, { ...msg, new: true, addedAt: timestampWithDelay });
                }, delay);
            });
        }
    }, [socket, subIsActive]);

    return { realTimeNews };
};

export default useNews;
