"use client";

import { useRef } from "react";

export default function Toggle({
    className,
    disabled,
    checked = false,
    onChange = console.log,
}: {
    className?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <div className="form-control">
            <input
                ref={ref}
                type="checkbox"
                className={`toggle-27AF8F toggle cursor-pointer border-none hover:brightness-75 ${className}`}
                checked={checked}
                disabled={disabled}
                onChange={(e) => {
                    if (ref.current) {
                        ref.current.blur();
                    }
                    onChange(e);
                }}
            />
        </div>
    );
}
