import Modal from "@components/Modal";
import { Button } from "@/components";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { saveFilterSetById } from "@/services/news";
import Cookies from "js-cookie";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { Dispatch, SetStateAction, useState } from "react";
import Input from "@/components/form-elements/Input";
import { FilterSet } from "@/types/util";

export const ConfirmEditFilterSetModal = ({
    show,
    setShow,
    filterSet,
}: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    filterSet: FilterSet;
}) => {
    const { filterSets, setFilterSets } = useNewsFeedContext();
    const [filterSetName, setFilterSetName] = useState(filterSet.filterName);

    const handleEditFilterSet = async (e) => {
        e.preventDefault();

        if (!filterSetName || filterSet.filterName === filterSetName) {
            return;
        }
        try {
            saveFilterSetById({
                filterSet: {
                    ...filterSet,
                    filterName: filterSetName,
                },
                jwt: Cookies.get("stytch_session_jwt") as string,
            }).then(() => {
                setShow(false);
                const index = filterSets.findIndex((f) => f.filterIndex === filterSet.filterIndex);
                filterSets.splice(index, 1, {
                    ...filterSet,
                    filterName: filterSetName,
                });
                setFilterSets([...filterSets]);
                setFilterSetName("");
            });
        } catch (e) {
            console.error(e);
            setShow(false);
        }
    };

    return (
        <div className="relative z-[1000]">
            <Modal show={show} setShow={setShow} className="overflow-hidden px-0 pb-0 pt-0">
                <div className="flex w-[400px] flex-col items-center justify-center overflow-hidden rounded-lg bg-[#1C1F27] p-0">
                    <form className="flex flex-col items-center">
                        <div className="flex w-full flex-col items-center gap-1 px-4 pb-5 pt-4">
                            <div className="flex h-9 w-9 items-center justify-center rounded-full border-1 border-[#2F343F]">
                                <FunnelIcon className="h-[14px] w-[14px] text-[#7A869B]" strokeWidth={2.5} />
                            </div>
                            <div className="flex w-full flex-col items-center gap-4 px-0 pb-0 pt-2">
                                <h2 className="text-base text-white">Edit FilterSet Name</h2>

                                <Input
                                    className="h-10 gap-2 rounded-lg border-1 border-[#2F343F] bg-[#15181D] p-3 text-base text-[#A1ADC1]"
                                    input={filterSetName}
                                    setInput={setFilterSetName}
                                    placeholder="FilterSet Name"
                                />
                            </div>
                        </div>
                        <div className="flex items-start justify-center gap-2 px-4 pb-3 pt-0">
                            <Button
                                onClick={() => setShow(false)}
                                className="flex h-10 w-[180px] items-center justify-center gap-[10px] rounded-lg border-none bg-[#252932] px-[10px] py-3"
                            >
                                <p className="text-xs font-normal text-white">Close this</p>
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleEditFilterSet}
                                disabled={!filterSetName || filterSet.filterName === filterSetName}
                                className="flex h-10 w-[180px] items-center justify-center gap-[10px] rounded-lg border-none bg-[#2E72FF] px-[10px] py-3"
                            >
                                <p
                                    className={`text-xs font-normal ${
                                        !filterSetName ? "text-[#A1ADC1]" : "text-white"
                                    }`}
                                >
                                    Save Changes
                                </p>
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};
