import { SVGProps, memo } from "react";

function Unread(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M8 3H4.5A1.5 1.5 0 003 4.5v7A1.5 1.5 0 004.5 13h7a1.5 1.5 0 001.5-1.5V8"
                stroke="#7A869B"
                strokeWidth={props.strokeWidth || 1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx={12.5} cy={3.5} r={1.75} stroke="#7A869B" strokeWidth={1.5} />
        </svg>
    );
}

const MemoUnread = memo(Unread);
export default MemoUnread;
