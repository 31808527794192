"use client";

import Tooltip from "@/components/Tooltip";
import GlobalMarketStats from "@app/dashboard/GlobalMarketStats";
// import MainMarkets from "@app/dashboard/right-sidebar/MainMarkets";
import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
// import SymbolWatcher from "@app/TSWidgets/SymbolWatcher";
import { NextFont } from "next/dist/compiled/@next/font";
import { useSnapshot } from "valtio";
import { globalOverlayProxy } from "@/store/globalStore";
import { vercelEnv } from "@/services/environment";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import HourlyMarketInsights from "@app/TSWidgets/HourlyMarketInsights";

export default function RightSidebar({
    globalMetrics,
    mainMarkets,
    font,
}: {
    globalMetrics;
    mainMarkets;
    font: NextFont;
}) {
    const updatedGlobalOverlay = useSnapshot(globalOverlayProxy);

    // const tags = [
    //     { value: 10000, label: "All" },
    //     { value: 50, label: "Top 50" },
    //     { value: 100, label: "Top 100" },
    //     { value: 250, label: "Top 250" },
    //     { value: 500, label: "Top 500" },
    //     { value: 1000, label: "Top 1000" },
    //     { value: 2500, label: "Top 2500" },
    //     { value: 5000, label: "Top 5000" },
    // ];

    // const [selectedLimit, selectLimit] = useState(50);

    return (
        <aside
            className={`relative ${
                updatedGlobalOverlay.setZIndex ? `z-[-1]` : `z-[1]`
            } hidden h-[calc(100vh-68px)] w-[calc((100vw-32px)/3)] 1xl:block`}
        >
            <div className="fixed flex h-[calc(100vh-68px)] w-[inherit] flex-col gap-2">
                <div className="w-full overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
                    <GlobalMarketStats globalMetrics={globalMetrics} />
                </div>
                {/* <HourlyMarketInsights /> */}
                {/* <div className="relative flex grow overflow-hidden">
                    <div className="relative flex w-full grow overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
                        <SymbolWatcher font={font} />
                    </div>
                </div> */}
                {vercelEnv !== "production" ? (
                    // <div className="relative flex grow overflow-hidden">
                    //     <div className="relative flex w-full grow overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
                    //         <SymbolWatcher font={font} />
                    //     </div>
                    // </div>
                    <HourlyMarketInsights />
                ) : (
                    <div className="relative flex grow overflow-hidden rounded-lg border-1 border-[#252932] bg-[#1C1F27]">
                        <span className="absolute left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-6 text-sm text-primary">
                            <MemoFullBlockbeatLogo className="scale-[12] opacity-50" />
                            <Tooltip
                                className="tooltip-custom-warning flex cursor-pointer items-center justify-center gap-1 text-xs font-light hover:brightness-95"
                                content="Coming Soon"
                            >
                                <p className="whitespace-nowrap text-[#7A869B] opacity-50">Screener Locked</p>
                                <QuestionMarkCircleIcon className="h-4 w-4 text-[#7A869B] opacity-50" />
                            </Tooltip>
                        </span>
                    </div>
                )}
                {/* <MainMarkets data={mainMarkets} /> */}
            </div>
        </aside>
    );
}
