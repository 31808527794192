import { SVGProps, memo } from "react";

function SearchTags(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
            <rect x={1.5} y={3.5} width={3} height={2} rx={1} stroke="#7A869B" />
            <rect x={6} y={4} width={5} height={1} rx={0.5} fill="#7A869B" />
            <rect x={1} y={8} width={10} height={1} rx={0.5} fill="#7A869B" />
        </svg>
    );
}

const MemoSearchTags = memo(SearchTags);
export default MemoSearchTags;
