export default function SubNav({
    tabs,
    onClick,
    selectedTab,
    className,
    truncate = true,
}: {
    tabs: string[];
    onClick: (e: string) => void;
    selectedTab: string;
    className?: string;
    truncate?: boolean;
}) {
    return (
        <nav
            className={`${className} z-30 flex items-center gap-3 overflow-auto text-xs font-normal text-[#7A869B]`}
        >
            {tabs.map((item: string, i: number) => {
                return (
                    <p
                        className={`max-w-[13rem] cursor-pointer whitespace-nowrap border-[#306CE8] px-1 py-[14px] font-light hover:brightness-75 ${
                            selectedTab === item && `border-b-2 font-normal text-white`
                        }`}
                        key={i}
                        onClick={() => onClick(item)}
                    >
                        {truncate ? (item?.length > 16 ? item.substring(0, 16) + "..." : item) : item}
                    </p>
                );
            })}
        </nav>
    );
}
