export enum CUSTOMIZE {
    LANDING_PAGE = "landing page",
    CUSTOMIZATION = "customization",
    ALL_COINS = "All Coins",
    TOP_50 = "Top 50",
    TOP_100 = "Top 100",
    TOP_200 = "Top 200",
    TOP_500 = "Top 500",
    TOP_1000 = "Top 1000",
}
