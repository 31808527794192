import MemoCopy from "@assets/SVG/Copy";
import { Dispatch, SetStateAction, useState } from "react";
import { BookmarkIcon, ShareIcon } from "@heroicons/react/24/outline";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import { useSelectedLayoutSegment } from "next/navigation";
import MemoUnread from "@assets/SVG/Unread";
import { createShortlink, markAsRead } from "@/services/news";
import { DISPLAY, News } from "@/types/api";
import cookie from "js-cookie";
import { useStytchUser } from "@stytch/nextjs";
import MemoLink from "@assets/SVG/Link";
import { getArticleSource } from "@/types/util";
import { BookmarkIcon as BookMarkSolid } from "@heroicons/react/24/solid";
import { createNewBookmarkEntry, deleteBookmark } from "@/services/bookmarks";
import bookmarkedListState from "@/store/bookmarkStore";
import { isEmpty } from "lodash";
import { globalOverlayProxy } from "@/store/globalStore";
import userPaymentState from "@/store/userPaymentStore";
import { useSnapshot } from "valtio";

export default function NewsListItemHeadline({
    setShowShareModal,
    setShowAlert,
    setNews,
    setNewsItem,
    setAlertMessage,
    news,
}: {
    setShowShareModal: Dispatch<SetStateAction<boolean>>;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    setNews: Dispatch<SetStateAction<News>>;
    setNewsItem: Dispatch<SetStateAction<News>>;
    setAlertMessage: Dispatch<SetStateAction<string>>;
    news: News;
}) {
    let article = news;

    if (isEmpty(news.headline.trim())) {
        article = { ...news, headline: news.content };
    }
    const [isHovered, setIsHovered] = useState(false);
    const { display, copy, renderedNews, setRenderedNews, filterSet, setShowUpgradeModal } =
        useNewsFeedContext();
    const segment = useSelectedLayoutSegment();
    const isSelected = article.slug === decodeURIComponent(segment!);
    const newsSource = getArticleSource(article.source);
    const subscriptionSnapshot = useSnapshot(userPaymentState);
    const userIsPro = subscriptionSnapshot.subIsActive;

    // const [shareableLink, setShareableLink] = useState<string>("");

    const user = useStytchUser();

    function addBookmarkFunction() {
        if (!userIsPro) {
            globalOverlayProxy.setZIndex = true;
            return setShowUpgradeModal(true);
        }
        setAlertMessage("Article Saved");
        setShowAlert(true);
        setNews({ ...article, bookmarked: true });
        createNewBookmarkEntry({
            jwt: cookie.get("stytch_session_jwt") as string,
            slug: article.slug,
            url: article.url as string,
        }).then(() => {
            const allBookmarks = bookmarkedListState.data;
            bookmarkedListState.data = [...allBookmarks, article.slug];
        });
    }

    function deleteBookmarkFunction() {
        if (!userIsPro) {
            globalOverlayProxy.setZIndex = true;
            return setShowUpgradeModal(true);
        }
        setAlertMessage("Article Unsaved");
        setShowAlert(true);
        setNews({ ...article, bookmarked: false });
        deleteBookmark({
            jwt: cookie.get("stytch_session_jwt") as string,
            slug: article.slug,
        }).then(() => {
            const index = bookmarkedListState.data.findIndex((item) => item === article.slug);
            const allBookmarks = bookmarkedListState.data;
            allBookmarks.splice(index, 1);
            bookmarkedListState.data = allBookmarks;
            if (filterSet.filterName === "Saved") {
                const index = renderedNews.findIndex((item) => item.SK === article.slug);
                const allRenderedNews = renderedNews;
                allRenderedNews.splice(index, 1);
                setRenderedNews([...allRenderedNews]);
            }
        });
    }

    return (
        <div
            className="relative flex grow justify-between"
            onPointerOver={() => setIsHovered(true)}
            // onPointerOut={() => setIsHovered(false)}
            // TODO: Isaac wants it that when any of the hover items is clicked, the hover does not disappear, which is why I have disabled the onPointerOut
        >
            <p
                className={`mb-1 line-clamp-2 items-center text-sm font-normal  leading-4 ${
                    isSelected ? "text-white" : article.read ? "text-[#505A68]" : "text-white"
                } transition-all duration-1000 ease-out`}
            >
                {/* some twitter news do not have headlines */}
                {!article.headline || article.iconType === "Twitter"
                    ? article.content
                    : article.headline}{" "}
                {display === DISPLAY.THIN && (
                    <span className="text-xs font-light text-[#7A869B]">{newsSource}</span>
                )}
            </p>

            {/* {display !== DISPLAY.THIN && ( */}
            <div
                className="invisible absolute -top-[3px] right-0 z-50 flex h-[24px] items-center gap-3 rounded-lg border-1 border-[#272C34] bg-[#16191F] px-3 py-0 shadow-[0px_4px_16px_rgba(21,24,29,0.25)] group-hover:visible"
                onClick={(e) => e.stopPropagation()}
            >
                {article.read && (
                    <div
                        onClick={async () =>
                            markAsRead({ slug: article.slug, jwt: cookie.get("stytch_session_jwt") }).then(
                                () => setNews({ ...article, read: false }),
                            )
                        }
                        className="flex h-5 w-5 cursor-pointer items-center justify-center hover:brightness-75"
                    >
                        <MemoUnread className="h-4 w-4" strokeWidth={2} />
                    </div>
                )}
                <div
                    onClick={() => {
                        copy(article.headline + " - from BlockBeat.io");
                        setAlertMessage("Headline copied!");
                        setShowAlert(true);
                        setIsHovered(true);
                    }}
                    className="flex h-5 w-5 cursor-pointer items-center justify-center hover:brightness-75"
                >
                    <MemoCopy className="h-4 w-4" />
                </div>
                <div className="flex h-5 w-5 cursor-pointer items-center justify-center hover:brightness-75">
                    {article?.bookmarked ? (
                        <BookMarkSolid
                            onClick={() => deleteBookmarkFunction()}
                            className="h-4 w-4 text-[#7A869B]"
                        />
                    ) : (
                        <BookmarkIcon
                            onClick={() => addBookmarkFunction()}
                            className="h-4 w-4 text-[#7A869B]"
                            strokeWidth={2.5}
                        />
                    )}
                </div>

                <div
                    className="flex h-5 w-5 cursor-pointer items-center justify-center hover:brightness-75"
                    onClick={() => {
                        createShortlink({ slug: article.slug, affiliateUserId: user.user?.user_id }).then(
                            (res) => {
                                const shareableLink = `https://bb.buzz/s/${res.shortlink}`;
                                copy(shareableLink);
                                setAlertMessage("Article link copied!");
                                setShowAlert(true);
                            },
                        );
                    }}
                >
                    <MemoLink strokeWidth={2.5} className="h-4 w-4 cursor-pointer hover:brightness-75" />
                </div>
                <div
                    className="flex h-5 w-5 cursor-pointer items-center justify-center hover:brightness-75"
                    onClick={() => {
                        setNewsItem(article);
                        setShowShareModal(true);
                    }}
                >
                    <ShareIcon className="h-4 w-4 text-[#7A869B]" strokeWidth={2.5} />
                </div>
            </div>
            {/* )} */}
        </div>
    );
}
