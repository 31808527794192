/* eslint-disable react-hooks/exhaustive-deps */
import { searchByTagsPayload, searchNewsByTags, searchSingleTag } from "@/services/search";
import {
    SearchPayload,
    clearOutOfContextProxy,
    clearSearchProxy,
    outOfContextProxy,
    searchProxy,
} from "@/store/searchStore";
import userPaymentState from "@/store/userPaymentStore";
import { useNewsFeedContext } from "@app/dashboard/news-feed/NewsFeedContext";
import TagSearchResult from "@app/dashboard/news-feed/Search/TagSearchResult";
import { SearchType } from "@app/dashboard/news-feed/searchType";
import MemoSearchTags from "@assets/SVG/SearchTags";
import { MagnifyingGlassIcon, PlusIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useStytchSession } from "@stytch/nextjs";
import { concat, debounce, isEmpty, uniqBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { useSnapshot } from "valtio";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
import { UpgradeSection } from "@/types/util";

export const searchStyles = {
    singleValue: (baseStyles) => ({
        ...baseStyles,
        fontSize: "8px",
        color: "white",
        marginRight: 0,
        position: "relative",
        left: "1px",
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        paddingRight: 0,
        paddingLeft: 0,
        height: "100%",
        paddingTop: 0,
        paddingBottom: 0,
        alignContent: "center",
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        paddingTop: 0,
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: "16px",
        color: "#a1adc1",
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#252932",
        paddingLeft: "8px",
        paddingRight: "8px",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#252932",
        borderRadius: "8px",
        width: "45px",
    }),
    indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: "100%",
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        padding: 0,
        width: "9px",
        color: "#FFFFFF",
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "8px",
        border: "none",
        backgroundColor: "#2455B7",
        minHeight: "0",
        height: "16px",
        width: "27px",
        cursor: "pointer",
    }),
};

export function Search() {
    const {
        filterSet,
        setFilterSet,
        filterSets,
        setScrollToTop,
        showSearchNewslist,
        setShowSearchNewslist,
        // setPayload,
        fieldsPayload,
        setFieldsPayload,
        searchQuery,
        setSearchQuery,
        searchQueryCategory,
        setSearchQueryCategory,
        setSearchNewsList,
        loading,
        setLoading,
        setNewsLoading,
        setNewSearchedNews,
        hideResults,
        setHideResults,
        neededFilterSet,
        setShowResultsBanner,
    } = useNewsFeedContext();

    const [input, setInput] = useState("");
    const [loadMoreTagResults, setLoadMoreTagResults] = useState(false);
    const [tagSearchResponse, setTagSearchResponse] = useState([] as any[]);
    const [peopleResults, setPeopleResults] = useState([] as any[]);
    const [companiesResults, setCompaniesResults] = useState([] as any[]);
    const [sourcesResults, setSourcesResults] = useState([] as any[]);
    const [assetsResults, setAssetsResults] = useState([] as any[]);
    const [othersResults, setOthersResults] = useState([] as any[]);
    const [tagBatch, setTagBatch] = useState(0);
    const [show, setShow] = useState(false);
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [andOrItem, setAndOrItem] = useState({
        value: "",
        label: "",
    });

    const outOfContextSnapshot = useSnapshot(outOfContextProxy);

    const [typeOfNewsSearch, setTypeOfNewsSearch] = useState(SearchType.TAG_FIELD_SEARCH);

    const { session } = useStytchSession();

    const [firstVisit, setFirstVisit] = useLocalStorageState("firstVisit", {
        defaultValue: 0,
    });

    useEffect(() => {
        if (typeOfNewsSearch != SearchType.KEYWORD_SEARCH) {
            return;
        }
        debounceSearch.current(input);
    }, [input]);

    useEffect(() => {
        const tagResultsExist =
            !isEmpty(peopleResults) ||
            !isEmpty(companiesResults) ||
            !isEmpty(sourcesResults) ||
            !isEmpty(assetsResults) ||
            !isEmpty(othersResults);

        if (loadMoreTagResults && tagResultsExist) {
            const oldBatch = tagBatch;
            const newBatch = oldBatch + 1;
            setTagBatch((tagBatch) => tagBatch + 1);
            // the state above is to be used down the stretch.
            const from = newBatch * 10;
            const paginatedPayload = {
                tag: input,
                from: from,
            };

            searchSingleTag(paginatedPayload).then((res) => {
                const newResults = res.response;
                if (!isEmpty(newResults)) {
                    const assets: any[] = newResults?.filter((item: any) => item.key === "ASSET");
                    const source: any[] = newResults?.filter((item: any) => item.key === "SOURCE");
                    const companies: any[] = newResults?.filter((item: any) => item.key === "ORG");
                    const people: any[] = newResults?.filter((item: any) => item.key === "PERSON");
                    const others: any[] = newResults?.filter((item: any) => item.key === "OTHER");

                    const assetHits = assets?.[0]?.top_tags?.hits?.hits;
                    const sourceHits = source?.[0]?.top_tags?.hits?.hits;
                    const companiesHits = companies?.[0]?.top_tags?.hits?.hits;
                    const peopleHits = people?.[0]?.top_tags?.hits?.hits;
                    const othersHits = others?.[0]?.top_tags?.hits?.hits;

                    if (!isEmpty(assetHits)) {
                        const concatedAssets = concat(assetsResults, assetHits);
                        const uniqueResults = uniqBy(concatedAssets, "_id");
                        setAssetsResults(uniqueResults);
                    }

                    if (!isEmpty(sourceHits)) {
                        const concatedSources = concat(sourcesResults, sourceHits);
                        const uniqueResults = uniqBy(concatedSources, "_id");
                        setSourcesResults(uniqueResults);
                    }

                    if (!isEmpty(companiesHits)) {
                        const concatedCompanies = concat(companiesResults, companiesHits);
                        const uniqueResults = uniqBy(concatedCompanies, "_id");
                        setCompaniesResults(uniqueResults);
                    }

                    if (!isEmpty(peopleHits)) {
                        const concatedPeople = concat(peopleResults, peopleHits);
                        const uniqueResults = uniqBy(concatedPeople, "_id");
                        setPeopleResults(uniqueResults);
                    }

                    if (!isEmpty(othersHits)) {
                        const concatedOthers = concat(othersResults, othersHits);
                        const uniqueResults = uniqBy(concatedOthers, "_id");
                        setOthersResults(uniqueResults);
                    }
                }
            });
        }
    }, [loadMoreTagResults]);

    useEffect(() => {
        if (searchQuery?.[0] || searchQuery?.[1]) {
            if (!showSearchNewslist) {
                setShowSearchNewslist(true);
            }
            setInput("");
        } else {
            setShowSearchNewslist(false);
            setShowResultsBanner(false);
        }
    }, [searchQuery?.[0], searchQuery?.[1]]);

    useEffect(() => {
        if (typeOfNewsSearch != SearchType.TAG_FIELD_SEARCH) {
            return;
        }

        if (searchQuery[0]) {
            setFieldsPayload({} as searchByTagsPayload);
            // setPayload({ query1: searchQuery[0] } as searchByTagsPayload);
            if (!input) {
                setHideResults(true);
                try {
                    setLoading(true);
                    setTypeOfNewsSearch(SearchType.TAG_FIELD_SEARCH);
                    doSearch();
                } catch (error) {
                    setLoading(false);
                    setHideResults(true);
                }
            }
        }
    }, [searchQuery[0], searchQuery[1], andOrItem]);

    useEffect(() => {
        if (input) {
            setHideResults(false);
            setFieldsPayload({} as searchByTagsPayload);

            if (tagBatch > 0) {
                setTagBatch(0);
            }
            setLoading(true);
            searchSingleTag({ tag: input }).then((res) => {
                const data: [] = res.response;
                if (!isEmpty(data)) {
                    setTagSearchResponse(data);

                    const assets: any[] = data?.filter((item: any) => item.key === "ASSET");
                    const source: any[] = data?.filter((item: any) => item.key === "SOURCE");
                    const companies: any[] = data?.filter((item: any) => item.key === "ORG");
                    const people: any[] = data?.filter((item: any) => item.key === "PERSON");
                    const others: any[] = data?.filter((item: any) => item.key === "OTHER");

                    const assetHits = assets?.[0]?.top_tags?.hits?.hits;
                    const sourceHits = source?.[0]?.top_tags?.hits?.hits;
                    const companiesHits = companies?.[0]?.top_tags?.hits?.hits;
                    const peopleHits = people?.[0]?.top_tags?.hits?.hits;
                    const othersHits = others?.[0]?.top_tags?.hits?.hits;

                    if (!isEmpty(assetHits)) {
                        setAssetsResults(assetHits);
                    } else setAssetsResults([]);

                    if (!isEmpty(sourceHits)) {
                        setSourcesResults(sourceHits);
                    } else setSourcesResults([]);

                    if (!isEmpty(companiesHits)) {
                        setCompaniesResults(companiesHits);
                    } else setCompaniesResults([]);

                    if (!isEmpty(peopleHits)) {
                        setPeopleResults(peopleHits);
                    } else setPeopleResults([]);

                    if (!isEmpty(othersHits)) {
                        setOthersResults(othersHits);
                    } else setOthersResults([]);
                } else {
                    setTagSearchResponse([]);
                }
                setLoading(false);
            });
        }
    }, [input]);

    useEffect(() => {
        if (!firstVisit) {
            setFirstVisit(Date.now());
        }
    }, [firstVisit, setFirstVisit]);

    useEffect(() => {
        if (!session) {
            if (firstVisit) {
                const elapsedTime = Date.now() - firstVisit;
                if (elapsedTime > LOCKOUT_DURATION_MS) {
                    setHideResults(true);
                }
            } else setTimeout(() => setHideResults(true), 15000);
        }
    }, [session, firstVisit]);

    useEffect(() => {
        if (outOfContextSnapshot.category && outOfContextSnapshot.tag) {
            addSearchItem(outOfContextSnapshot.tag, outOfContextSnapshot.category);
        }
    }, [outOfContextSnapshot.category, outOfContextSnapshot.tag]);

    const searchStore = useSnapshot(searchProxy) as SearchPayload;
    const subscriptionSnapshot = useSnapshot(userPaymentState);

    const keyword = input;

    const userIsPro = subscriptionSnapshot.subIsActive;

    const filterForLast7Days = userIsPro ? false : true;

    const LOCKOUT_DURATION_MS = 15000; // 15 seconds

    const inputRef = useRef<HTMLInputElement>(null);

    const doSearch = async (searchPayload?: any) => {
        if (filterSet.filterName === "Saved") {
            // HACK: I have not found a way to search while viewing saved news items.
            // Let's run with this for now
            const customized = filterSets.find((f) => f.filterName === "Customized");
            if (customized) setFilterSet(customized);
        }
        searchProxy.from = 0;
        searchProxy.filterForLast7Days = filterForLast7Days;

        setScrollToTop(true);
        setNewSearchedNews([]);
        try {
            setNewsLoading(true);
            const res = await searchNewsByTags(neededFilterSet, {
                ...(searchPayload || searchStore),
                primarySourcesOnly: filterSet.showPrimarySourcesOnly,
            });

            searchProxy.total = res.total;
            setSearchNewsList(res?.results || []);
            if (!showSearchNewslist) setShowSearchNewslist(true);
            setNewsLoading(false);
        } catch (e) {
            // noop
        } finally {
            setHideResults(true);
            setLoading(false);
        }
    };

    const runSearch = (text: string) => {
        searchProxy.headline = text;
        doSearch(searchProxy);
    };

    const debounceSearch = useRef(debounce(runSearch, 300));

    const clearSearch = () => {
        setShowResultsBanner(false);
        setInput("");
        setTypeOfNewsSearch(SearchType.TAG_FIELD_SEARCH);
        setSearchQueryCategory([]);
        setShowSearchNewslist(false);
        clearSearchProxy();
        setSearchQuery([]);
        if (!isEmpty(fieldsPayload) && showSearchNewslist) {
            setShowSearchNewslist(false); //here
            setFieldsPayload({} as searchByTagsPayload);
        }
        setScrollToTop(true);
    };

    const setKeywordSearch = () => {
        setTypeOfNewsSearch(SearchType.KEYWORD_SEARCH);
        searchProxy.headline = input;
        doSearch(searchProxy);
    };

    const addSearchItem = (tag: string, category: string) => {
        // if (!userIsPro) {
        //     setShow(true);
        // } else {
        if (searchProxy[category] && !searchProxy[category]?.includes(tag)) {
            searchProxy[category].push(tag);
        }

        if (category === "source") {
            setSearchQueryCategory(["source"]);
            setSearchQuery([tag]);
            setInput("");
            return;
        } else if (searchQuery.length < 2) {
            const allCategories = [...searchQueryCategory];
            const newCategories = [...allCategories, category];
            setSearchQueryCategory([...newCategories]);

            const allItems = [...searchQuery];
            const isAlreadyInList = allItems.some((i) => i === tag);

            if (!isAlreadyInList) {
                const newList = [...allItems, tag];

                setSearchQuery([...newList]);
                return setInput("");
            }
        }
        // }
    };

    const addNewItem = (e: any) => {
        if (e.key === "Enter") {
            setKeywordSearch();
        } else if (e.target.value == "" && searchQuery.length == 0) {
            // If the user removes all text from the search input and the search has no
            // source, people, tags selection, the search resets back to default mode.
            clearSearch();
        }
    };

    const removeItem = (i: number) => {
        clearOutOfContextProxy();
        const item = searchQuery[i];
        ["assets", "sources", "tags"].forEach((attr) => {
            const idx = searchProxy[attr].indexOf(item);
            if (idx != -1) {
                searchProxy[attr].splice(idx, 1);
            }
        });

        searchQuery.splice(i, 1);
        searchQueryCategory.splice(i, 1);

        setSearchQuery([...searchQuery]);
        setSearchQueryCategory([...searchQueryCategory]);
    };

    useEffect(() => {
        // Focus on the input element when the component mounts
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current, searchQuery[0]]);

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}
            <UpgradeModal
                show={show}
                setShow={setShow}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Search}
            >
                <></>
            </UpgradeModal>
            <div
                className="relative flex w-[calc(100%-40px)] rounded-[50px] bg-[#252932] px-2"
                id="searchbox"
                onClick={(e) => e.stopPropagation()}
            >
                <MagnifyingGlassIcon className="m-auto h-4 w-4 shrink-0 text-[#7A869B]" />
                <div
                    className={`flex h-8 w-[calc(100%-32px)] gap-1 pl-2 ${
                        // eslint-disable-next-line no-extra-boolean-cast
                        (!!searchQuery?.[1] || !!input) && "pr-2"
                    }`}
                >
                    <div className={`flex gap-2 overflow-auto ${!searchQuery[0] && "w-full"}`}>
                        {searchQuery[0] && (
                            <div
                                className={`flex ${
                                    searchQuery?.[1] ? "mr-6 w-full" : "w-auto"
                                } items-center gap-2`}
                            >
                                {searchQuery[0] && (
                                    <div className="flex items-center gap-1 rounded-2xl bg-[#2F343F] px-2 py-1">
                                        <MemoSearchTags className="h-4 w-4" />
                                        <div className="truncate text-xs text-white">{searchQuery[0]}</div>
                                        <XMarkIcon
                                            className="h-3 w-3 cursor-pointer text-[#7A869B] hover:brightness-75"
                                            strokeWidth={4}
                                            onClick={() => removeItem(0)}
                                        />
                                    </div>
                                )}
                                {searchQuery[0] &&
                                    (!andOrItem.label ? (
                                        <div className="flex items-center gap-1">
                                            <PlusIcon className="h-4 w-4 text-white" />
                                            <div
                                                onClick={() => {
                                                    searchProxy.connector = "AND";
                                                    setAndOrItem({
                                                        value: "AND",
                                                        label: "and",
                                                    });
                                                }}
                                                className="flex cursor-pointer items-start rounded-3xl bg-[#306CE8] px-1 py-0 text-xs font-light"
                                            >
                                                and
                                            </div>
                                            /
                                            <div
                                                onClick={() => {
                                                    searchProxy.connector = "OR";
                                                    setAndOrItem({
                                                        value: "OR",
                                                        label: "or",
                                                    });
                                                }}
                                                className="flex cursor-pointer items-start rounded-3xl bg-[#306CE8] px-1 py-0 text-xs font-light"
                                            >
                                                or
                                            </div>
                                        </div>
                                    ) : (
                                        <p
                                            onClick={() =>
                                                setAndOrItem({
                                                    value: "",
                                                    label: "",
                                                })
                                            }
                                            className="cursor-pointer text-xs font-light text-[#A1ADC1]"
                                        >
                                            {andOrItem.label}
                                        </p>
                                    ))}

                                {searchQuery[1] && (
                                    <div className="flex items-center gap-1 rounded-2xl bg-[#2F343F] px-2 py-1">
                                        <MemoSearchTags className="h-4 w-4" />
                                        <div className="truncate text-xs text-white">{searchQuery[1]}</div>
                                        <XMarkIcon
                                            onClick={() => removeItem(1)}
                                            strokeWidth={4}
                                            className="h-3 w-3 cursor-pointer text-[#7A869B] hover:brightness-75"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {!searchQuery[1] && (
                            <input
                                onKeyUp={(e: any) => addNewItem(e)}
                                onChange={(e) => setInput(e.target.value)}
                                onFocus={() => setHideResults(false)}
                                //onPointerOver={() => setHideResults(false)}
                                className="input flex h-full w-full min-w-[4rem] border-none bg-[#252932] py-2 pl-0 pr-0 text-xs font-light text-[#A1ADC1] outline-0 focus:outline-0 focus:ring-0"
                                placeholder={!searchQuery?.[0] ? "Coins, Sources, People, Companies..." : ""}
                                value={input}
                                ref={inputRef}
                            />
                        )}
                    </div>
                </div>

                {(input || searchQuery.length > 0) && (
                    <XCircleIcon
                        onClick={() => clearSearch()}
                        className=" m-auto h-4 w-4 shrink-0 cursor-pointer text-[#7A869B] hover:brightness-75"
                    />
                )}
                {typeOfNewsSearch != SearchType.KEYWORD_SEARCH && !hideResults && input.length > 0 && (
                    <TagSearchResult
                        assetsResults={assetsResults}
                        companiesResults={companiesResults}
                        keyword={keyword}
                        loading={loading}
                        othersResults={othersResults}
                        peopleResults={peopleResults}
                        setIsVisible={setLoadMoreTagResults}
                        sourcesResults={sourcesResults}
                        tagSearchResponse={tagSearchResponse}
                        typeOfSearch={typeOfNewsSearch}
                        setKeywordSearch={() => setKeywordSearch()}
                        setShow={setShow}
                        addSearchItem={addSearchItem}
                    />
                )}
            </div>
        </>
    );
}
