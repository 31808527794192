// bookmarkedListState.js
import { FilterSet } from "@/types/util";
import { proxy } from "valtio";

const filterSetList = proxy({
    data: [] as FilterSet[],
    isLoading: false,
    error: null,
});

export default filterSetList;
