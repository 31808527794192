import Modal from "@components/Modal";
import { Dispatch, SetStateAction } from "react";
import MemoYouTubeVideo from "@assets/SVG/YouTubeVideo";

export default function YoutubeVideoModal({
    url,
    show,
    setShow,
}: {
    url: string;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) {
    return (
        <Modal
            showCloseButton
            show={show}
            right
            dismissible={false}
            setShow={setShow}
            className="overflow-hidden px-0 pb-0 pt-0"
            isYoutube
        >
            <div className="flex w-[200px] flex-col items-center justify-center overflow-hidden rounded-lg bg-[#1C1F27] p-0">
                <MemoYouTubeVideo videoId={url.split("youtube-")[1]} />
            </div>
        </Modal>
    );
}
