import { FilterSet } from "@/types/util";

export function filterSetFilter(filterSet: FilterSet, item: string) {
    if (filterSet.filterIndex === "all") {
        return true;
    }

    if (item === "BlockBeat") {
        return filterSet.showBlockbeatNews;
    }
    if (item === "Twitter") {
        return filterSet.showTwitterNews;
    }
    if (item === "Reddit") {
        return filterSet.showRedditNews;
    }
    if (item === "News") {
        return filterSet.showNewsNews;
    }
    if (item === "Press Release") {
        return filterSet.showPressRelease;
    }
    if (item === "Government") {
        return filterSet.showGovernmentPress;
    }
    if (item === "Blog") {
        return filterSet.showBlogNews;
    }
    if (item === "YouTube") {
        return filterSet.showYouTubeNews;
    }
    if (item === "Podcast") {
        return filterSet.showPodcastNews;
    }

    // Default to true for other items
    return true;
}

export function generateStructuredString(...args) {
    if (args.length === 0) {
        return "";
    }
    const pressReleaseIndex = args.findIndex((item) => item === "Press Release");
    if (pressReleaseIndex >= 0) {
        args.splice(pressReleaseIndex, 1, "*Release");
    }

    const structuredString = args.join(" OR ");

    return structuredString;
}
