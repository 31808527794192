import { useEffect, useState } from "react";
import { fetchNewsItem } from "@/services/news";
import { News } from "@/types/api";

const newsCache = new Map<string, News>();
const newsPromiseCache = new Map<string, Promise<News>>();

export const fetchCachedNews = async (articleId: string): Promise<News> => {
    if (newsCache.has(articleId)) {
        return newsCache.get(articleId)!;
    }
    const cached = newsPromiseCache.get(articleId);
    if (cached) {
        return cached;
    }
    const newsPromise = fetchNewsItem(articleId);
    newsPromiseCache.set(articleId, newsPromise);
    newsPromise.then((news) => {
        newsCache.set(articleId, news);
    });
    return newsPromise;
};

const useNews = (articleId: string): News | undefined => {
    const [news, setNews] = useState<News | undefined>(newsCache.get(articleId));
    useEffect(() => {
        fetchCachedNews(articleId).then(setNews);
    }, [articleId]);
    return news;
};

export default useNews;
